import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Input, Button, Modal, Space } from 'antd';
import { Helmet } from 'react-helmet';

import * as userActions from '../../core/user/userActions';

import logoSrc from '../../assets/images/logo.png';
import bgSrcMob from '../../assets/images/login/bg-mobile@2x.jpg';
import bgSrcDeskt from '../../assets/images/login/bg@2x.jpg';
import icon from '../../assets/favicon.ico';
import './style.scss';

const { APP_NAME } = require('../../core/constants').default;

function LoginPage() {

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [forgetPasswordModalVisible, setForgetPasswordModalVisible] = useState(false);

    const onFinish = (values) => {
        dispatch(userActions.loginRequest({
            username: values.email,
            password: values.password,
        }));
    };

    const onFinishFailed = ({ errorFields }) => {
        form.scrollToField(errorFields[0].name);
    };

    const handleForgotFinish = (values) => {
        dispatch(userActions.forgotPasswordRequest({
            email: values.email,
        }));
        setForgetPasswordModalVisible();
    };

    const forgotFinishFailed = ({ errorFields }) => {
        form.scrollToField(errorFields[0].name);
    };

    return (
        <div className="login-wrap">
            <Helmet
                title={`${APP_NAME}`}
                titleTemplate={`${APP_NAME}`}
                defaultTitle={`${APP_NAME}`}
                meta={[
                    { name: 'description', content: `${APP_NAME} website` },
                    { property: 'og:type', content: 'website' },
                ]}>
                <link rel="shortcut icon" href={icon} />
            </Helmet>
            <div className="login-info">
                <div className="bg-absolute ">
                    <picture>
                        <source srcSet={bgSrcDeskt} media="(min-width: 768px)" />
                        <img src={bgSrcMob} alt="David Hayes" />
                    </picture>
                </div>
                {/* <img src={bgSrcMob} alt="background_img" className="bgImgMob" /> */}
                <div className="login-info__text">
                    <h1 className="visibility">David Hayes</h1>
                    <img src={logoSrc} alt="logo" className="main-logo" />
                    <h2 className="title">Client Portal</h2>
                    <p className="description">
                        Welcome to the client Internal Compliance Plan (ICP) Review Portal.
                        The purpose of this tool is to provide a secure platform where your Internal Compliance
                        Programme (ICP) can be used to assess the export control risk exposure of the business.
                        Following submission it will be compared with internationally recommended best practice and a bespoke gap analysis performed.
                    </p>
                </div>
            </div>
            <div className="login-form-box">
                <div className="form">
                    <h3 className="form-title h-1">Sign In</h3>
                    <Form
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        layout="vertical">
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[{ type: 'email', message: 'The input is not a valid email!' }, { required: true, message: 'Please input your email.' }]}>
                            <Input placeholder="Enter your email" />
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[{ required: true, message: 'Please input your password.' }]}>
                            <Input.Password placeholder="Enter password" />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" size="large" htmlType="submit" block className="mt-3">
                                Sign In
                            </Button>
                        </Form.Item>
                    </Form>
                    <div className="text-center mt-3">
                        <Button onClick={setForgetPasswordModalVisible} type="link" className="button-lin " href="#">
                            Forgotten password
                        </Button>
                    </div>
                </div>
            </div>

            <Modal centered width={540} footer={null} visible={forgetPasswordModalVisible} onOk={() => { setForgetPasswordModalVisible(false); }} onCancel={() => { setForgetPasswordModalVisible(false); }}>
                <h4 className="h-3 text-center">Forgot password</h4>
                <div className="form-wrap">
                    <Form onFinish={handleForgotFinish} onFinishFailed={forgotFinishFailed} layout="vertical">
                        <Form.Item label="Email" name="email" rules={[{ type: 'email', message: 'The input is not valid E-mail!' }, { required: true, message: 'Please input your E-mail!' }]}>
                            <Input />
                        </Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit" className="wide">
                                Reset my password
                            </Button>
                            <Button className="link-green btn-empty underlined" onClick={() => { setForgetPasswordModalVisible(false); }}>
                                cancel
                            </Button>
                        </Space>
                    </Form>
                </div>
            </Modal>

        </div>
    );
}
export default LoginPage;
