import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import './style.scss';

function Answer(props) {
    const { question } = props;
    const { id } = question.id;

    const _renderAnswers = (col) => {
        const jsx = [];
        col.ansvalue.forEach((el, key) => {
            // eslint-disable-next-line react/no-array-index-key
            jsx.push(<div key={key}> { el.link && el.link !== '#' ? <Button type="link" download href={el.link}>Download</Button> : <p className="margin-right-2">{el.value} </p> } </div>);
        });
        return jsx;
    };

    return (
        <div
            key={id}
            className="table__row"
            style={{ gridTemplateColumns: '18fr 8fr' }}>
            {question.cols.map((col, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={idx} className="table__col">
                    <h6 className="table__caption">{['Question', 'Answer'][idx]}</h6>
                    {idx === 0 ? <p>{col.value} </p> : _renderAnswers(col) }
                </div>
            ))}
        </div>
    );
}

Answer.propTypes = {
    question: PropTypes.shape({
        cols: PropTypes.any,
        id: PropTypes.any,
    }).isRequired,
};

export default Answer;
