import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    DELETE_TOKEN_REQUEST: null,
    DELETE_TOKEN_SUCCESS: null,

    SAVE_TOKEN_REQUEST: null,
    SAVE_TOKEN_SUCCESS: null,

    LOGIN_REQUEST: null,
    LOGIN_SUCCESS: null,
    LOGIN_FAILURE: null,

    LOGOUT_REQUEST: null,
    LOGOUT_SUCCESS: null,
    LOGOUT_FAILURE: null,

    GET_QUESTIONNAIRE_INSTANCE_LIST_REQUEST: null,
    GET_QUESTIONNAIRE_INSTANCE_LIST_SUCCESS: null,
    GET_QUESTIONNAIRE_INSTANCE_LIST_FAILURE: null,

    WATCH_QUESTIONNAIRE_INSTANCE_REQUEST: null,
    WATCH_QUESTIONNAIRE_INSTANCE_SUCCESS: null,
    WATCH_QUESTIONNAIRE_INSTANCE_FAILURE: null,

    FORGOT_PASSWORD_REQUEST: null,
    FORGOT_PASSWORD_SUCCESS: null,
    FORGOT_PASSWORD_FAILURE: null,

    RESET_PASSWORD_REQUEST: null,
    RESET_PASSWORD_SUCCESS: null,
    RESET_PASSWORD_FAILURE: null,
});

export const {
    deleteTokenRequest,
    deleteTokenSuccess,
} = createActions(
    constants.DELETE_TOKEN_REQUEST,
    constants.DELETE_TOKEN_SUCCESS,
);

export const {
    saveTokenRequest,
    saveTokenSuccess,
} = createActions(
    constants.SAVE_TOKEN_REQUEST,
    constants.SAVE_TOKEN_SUCCESS,
);

export const {
    loginRequest,
    loginSuccess,
    loginFailure,
} = createActions(
    constants.LOGIN_REQUEST,
    constants.LOGIN_SUCCESS,
    constants.LOGIN_FAILURE,
);

export const {
    logoutRequest,
    logoutSuccess,
    logoutFailure,
} = createActions(
    constants.LOGOUT_REQUEST,
    constants.LOGOUT_SUCCESS,
    constants.LOGOUT_FAILURE,
);

export const {
    forgotPasswordRequest,
    forgotPasswordSuccess,
    forgotPasswordFailure,
} = createActions(
    constants.FORGOT_PASSWORD_REQUEST,
    constants.FORGOT_PASSWORD_SUCCESS,
    constants.FORGOT_PASSWORD_FAILURE,
);

export const {
    resetPasswordRequest,
    resetPasswordSuccess,
    resetPasswordFailure,
} = createActions(
    constants.RESET_PASSWORD_REQUEST,
    constants.RESET_PASSWORD_SUCCESS,
    constants.RESET_PASSWORD_FAILURE,
);

export const {
    getQuestionnaireInstanceListRequest,
    getQuestionnaireInstanceListSuccess,
    getQuestionnaireInstanceListFailure,
} = createActions(
    constants.GET_QUESTIONNAIRE_INSTANCE_LIST_REQUEST,
    constants.GET_QUESTIONNAIRE_INSTANCE_LIST_SUCCESS,
    constants.GET_QUESTIONNAIRE_INSTANCE_LIST_FAILURE,
);

export const {
    watchQuestionnaireInstanceRequest,
    watchQuestionnaireInstanceSuccess,
    watchQuestionnaireInstanceFailure,
} = createActions(
    constants.WATCH_QUESTIONNAIRE_INSTANCE_REQUEST,
    constants.WATCH_QUESTIONNAIRE_INSTANCE_SUCCESS,
    constants.WATCH_QUESTIONNAIRE_INSTANCE_FAILURE,
);
