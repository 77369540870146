import React from 'react';
import PropTypes from 'prop-types';

import Answer from '../../elements/Answer';

import './style.scss';

function AnswerCategory(props) {
    const { questionnaireAnswerCategory } = props;

    return (
        <div key={questionnaireAnswerCategory.id} className="table">
            <h5 className="h-5 table__title">{questionnaireAnswerCategory.title}</h5>
            <div className="table__head" style={{ gridTemplateColumns: '18fr 8fr' }}>
                {['Question', 'Answer']?.map((col, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={index} className="table__col">
                        <h6 className="table__caption">{col}</h6>
                    </div>
                ))}
            </div>
            {questionnaireAnswerCategory.answerList.map((question) => (
                <Answer key={question.id} question={question} questionnaireAnswerCategory={questionnaireAnswerCategory} />
            ))}
        </div>
    );
}

AnswerCategory.propTypes = {
    questionnaireAnswerCategory: PropTypes.shape({
        answerList: PropTypes.any,
        id: PropTypes.any,
        title: PropTypes.any,
    }).isRequired,
};

export default AnswerCategory;
