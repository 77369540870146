import { call, put, takeEvery, all } from 'redux-saga/effects';
import { message } from 'antd';
import { fetchApiAuth } from '../utils/api';
import history from '../utils/history';

import {
    getQuestionnaireSuccess,
    getQuestionnaireFailure,

    submitQuestionnaireSuccess,
    submitQuestionnaireFailure,

    saveQuestionnaireSuccess,
    saveQuestionnaireFailure,

    updateReminderSuccess,
    updateReminderFailure,

    removeReminderSuccess,
    removeReminderFailure,

    clearQuestionnaireSuccess,
    clearQuestionnaireFailure,

} from './questionnaireActions';

const {
    GET_QUESTIONNAIRE_REQUEST,
    SUBMIT_QUESTIONNAIRE_REQUEST,
    SAVE_QUESTIONNAIRE_REQUEST,
    UPDATE_REMINDER_REQUEST,
    REMOVE_REMINDER_REQUEST,
    CLEAR_QUESTIONNAIRE_REQUEST,
} = require('./questionnaireActions').constants;

function* getQuestionnaire({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `questionnaire/${payload.companyQuestionnaireId}/all-questions?regionId&companyQuestionnaireId=${payload.companyQuestionnaireId}`,
        });

        yield put(getQuestionnaireSuccess(response.data));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getQuestionnaireFailure(e.response ? e.response.data.message : e));
    }
}

function* submitQuestionnaire({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `questionnaire/${payload.companyId}/save-response?companyQuestionnaireId=${payload.companyQuestionnaireId}`,
            body: {
                responses: payload.response,
                startTime: payload.startTime,
                progress: payload.progress,
                submit: 1,
            },
        });

        yield put(submitQuestionnaireSuccess(response.data));
        history.push('/risk-score');
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(submitQuestionnaireFailure(e.response ? e.response.data.message : e));
    }
}

function* saveQuestionnaire({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `questionnaire/${payload.companyId}/save-response?companyQuestionnaireId=${payload.companyQuestionnaireId}`,
            body: {
                responses: payload.response,
                startTime: payload.startTime,
                progress: payload.progress,
            },
        });
        yield put(saveQuestionnaireSuccess(response.data));
        message.success('Questionnaire saved successfully');
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(saveQuestionnaireFailure(e.response ? e.response.data.message : e));
    }
}

function* updateReminder({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `questionnaire/${payload.questionnaire_id}/reminder`,
            body: {
                companyQuestionnaireId: payload.company_questionnaire_id,
                questionId: payload.question_id,
                reminderTime: payload.set_time,
                note: payload.note,
            },
        });
        message.success('Reminder saved');
        yield put(updateReminderSuccess(response.data));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(updateReminderFailure(e.response ? e.response.data.message : e));
    }
}

function* removeReminder({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'DELETE',
            url: `questionnaire/${payload.questionnaireId}/reminder?reminderId=${payload?.reminderId}`,
            body: {
                companyQuestionnaireId: payload.company_questionnaire_id,
                questionId: payload.question_id,
            },
        });
        const res = { result: response, data: payload };
        yield put(removeReminderSuccess(res));
    } catch (e) {
        message.error('Oops Something went wrong!');
        yield put(removeReminderFailure(e.response ? e.response : e));
    }
}

function* clearQuestionnaire() {
    try {
        yield put(clearQuestionnaireSuccess());
    } catch (e) {
        yield put(clearQuestionnaireFailure());
    }
}

/**
 * Watch actions
 */
export default function* userSaga() {
    yield all([
        takeEvery(CLEAR_QUESTIONNAIRE_REQUEST, clearQuestionnaire),
        takeEvery(GET_QUESTIONNAIRE_REQUEST, getQuestionnaire),
        takeEvery(SUBMIT_QUESTIONNAIRE_REQUEST, submitQuestionnaire),
        takeEvery(SAVE_QUESTIONNAIRE_REQUEST, saveQuestionnaire),
        takeEvery(UPDATE_REMINDER_REQUEST, updateReminder),
        takeEvery(REMOVE_REMINDER_REQUEST, removeReminder),
    ]);
}
