import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import srcProgress from '../../assets/images/progress.svg';
import './style.scss';

const RiskInfographic = ({ riskScore = 0, riskText = 'NA' }) => {

    const riskScoreDeg = useMemo(() => {
        const val = riskScore * 1.8;
        if (val > 180) return 180;
        if (val < 0) return 0;
        return val;
    }, [riskScore]);

    return (
        <div className="orbit">
            <img src={srcProgress} alt="Orbit" className="orbit__pic" />
            <div className="orbit__sun" style={{ transform: `rotate(${riskScoreDeg}deg)` }}>
                <div className="orbit__dot" />
            </div>
            <div className="orbit__text">
                <div className="orbit__text-numb">{riskScore}</div>
                <p>{riskText}</p>
            </div>
        </div>
    );
};

RiskInfographic.propTypes = {
    riskScore: PropTypes.number.isRequired,
    riskText: PropTypes.any.isRequired,
};
export default RiskInfographic;
