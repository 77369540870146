import { all } from 'redux-saga/effects';
import userSaga from './user/userSaga';
import questionnairesSaga from './questionnaires/questionnairesSaga';
import questionnaireSage from './questionnaire/questionnaireSaga';

// single entry point to start all Sagas at once
export default function* rootSaga() {
    yield all([
        userSaga(),
        questionnairesSaga(),
        questionnaireSage(),
    ]);
}
