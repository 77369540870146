import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Avatar, Row, Col, Dropdown, Menu } from 'antd';
import { useLocation } from 'react-router-dom';

import Icon from '../../elements/CustomSVGIcon';
import srcLogoSmall from '../../assets/images/logo-small.png';
import './style.scss';

import { logoutRequest } from '../../core/user/userActions';

function Header(props) {

    const { companyModel } = useSelector(state => state.user);

    const dispatch = useDispatch();

    const { headerTitle } = props;

    const handleLogout = () => {
        dispatch(logoutRequest());
    };

    const location = useLocation();
    const urlSegments = location.pathname.split('/');

    const menu = (
        <Menu>
            <Menu.Item key="1" onClick={handleLogout}>Log out</Menu.Item>
        </Menu>
    );

    const companyKeys = Object.keys(companyModel);
    let userImg = '';

    // extract logo from keys
    companyKeys.forEach(e => {
        if (e.match(/logo_/)) {
            userImg = companyModel[e] ? `${process.env.REACT_APP_API_URL}/storage/${companyModel[e]}` : '';
            return true;
        }
        return false;
    });

    return (
        <header className="header">
            <div className="header__inner">
                <Row type="flex" justify="space-between" align="middle" gutter={16}>
                    <Col xs={{ span: 12 }} md={{ span: 6 }}>
                        <div className="header__logo">
                            <img src={srcLogoSmall} alt="Logo" />
                            <h6 className="h-5">Export Control Portal</h6>
                        </div>
                    </Col>
                    <Col flex="auto" xs={{ order: 3 }} md={{ order: 2 }} className="header__middle-col">
                        <h3>{headerTitle}</h3>
                    </Col>
                    <Col xs={{ order: 2, span: 12 }} md={{ order: 3, span: 6 }}>
                        <div className={`toolbar  ${urlSegments[2] === 'reset' ? 'd-none' : ''}`}>
                            <Dropdown arrow overlay={menu} trigger={['click']} placement="bottomRight">
                                <div>
                                    <Avatar src={userImg} className="header__avatar" icon={<Icon name="user" />} />
                                    <Icon name="chevron-left" className="rotate-icon-down" />
                                </div>
                            </Dropdown>
                        </div>
                    </Col>
                </Row>
            </div>
        </header>
    );
}

Header.propTypes = {
    headerTitle: PropTypes.any.isRequired,
};

// Header.defaultProps = {
//     toolbar: [],
//     backLink: null,
//     floating: false,
//     progress: null,
// };

// Header.propTypes = {
//     toolbar: PropTypes.array,
//     backLink: PropTypes.object,
//     floating: PropTypes.bool,
//     progress: PropTypes.number,
//     actions: PropTypes.object.isRequired,
// };

// function mapStateToProps(state, ownProps) {
//     return {
//         ...ownProps,
//         user: state.user.userData,
//     };
// }

// function mapDispatchToProps(dispatch) {
//     return {
//         actions: bindActionCreators(
//             {
//                 ...userActions,
//             },
//             dispatch,
//         ),
//     };
// }
export default Header;
