import { Record } from 'immutable';

const {
    DELETE_TOKEN_REQUEST,
    DELETE_TOKEN_SUCCESS,

    SAVE_TOKEN_REQUEST,
    SAVE_TOKEN_SUCCESS,

    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,

    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE,

    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE,

    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,

    GET_QUESTIONNAIRE_INSTANCE_LIST_REQUEST,
    GET_QUESTIONNAIRE_INSTANCE_LIST_SUCCESS,
    GET_QUESTIONNAIRE_INSTANCE_LIST_FAILURE,

    WATCH_QUESTIONNAIRE_INSTANCE_REQUEST,
    WATCH_QUESTIONNAIRE_INSTANCE_SUCCESS,
    WATCH_QUESTIONNAIRE_INSTANCE_FAILURE,

} = require('./userActions').constants;

const InitialState = Record({
    // Boilerplate
    error: null,
    isFetching: false,
    isLoggedIn: false,
    loginUser: '',
    userSettings: {},
    user: {},
    userData: {},
    // Project specific
    companyModel: {},
});

const initialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function userReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

    switch (type) {
    // REQUEST
    case LOGIN_REQUEST:
    case DELETE_TOKEN_REQUEST:
    case SAVE_TOKEN_REQUEST:
    case LOGOUT_REQUEST:
    case FORGOT_PASSWORD_REQUEST:
    case RESET_PASSWORD_REQUEST:
    case GET_QUESTIONNAIRE_INSTANCE_LIST_REQUEST:
    case WATCH_QUESTIONNAIRE_INSTANCE_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    // SUCCESS
    case SAVE_TOKEN_SUCCESS:
    case LOGIN_SUCCESS:
        return state.set('isFetching', false)
            .set('loginUser', (payload && payload.data && payload.data.username ? payload.data.username : 'Logged In User'))
            .set('userData', payload.data.user)
            .set('companyModel', payload.data.companyData)
            .set('userSettings', (payload && payload.data && payload.data.settings ? payload.data.settings : {}))
            .set('isLoggedIn', true);
    case DELETE_TOKEN_SUCCESS:
    case LOGOUT_SUCCESS:
        return state.set('isFetching', false)
            .set('loginUser', '')
            .set('user', {})
            .set('isLoggedIn', false)
            .set('userData', {})
            .set('companyModel', {});

    case FORGOT_PASSWORD_SUCCESS:
    case RESET_PASSWORD_SUCCESS:
        return state.set('isFetching', false);

    case GET_QUESTIONNAIRE_INSTANCE_LIST_SUCCESS: {
        return state.set('questionnaireInstanceModelList', payload.data)
            .set('isFetching', false);
    }
    case WATCH_QUESTIONNAIRE_INSTANCE_SUCCESS: {
        const { questionnaireInstanceModelList } = state || {};
        questionnaireInstanceModelList[0] = payload.data;
        return state.set('questionnaireInstanceModel', questionnaireInstanceModelList)
            .set('isFetching', false);
    }

    // FAILURE
    case LOGIN_FAILURE:
    case LOGOUT_FAILURE:
        return state.set('isFetching', false)
            .set('isLoggedIn', false)
            .set('error', payload);

    case FORGOT_PASSWORD_FAILURE:
    case RESET_PASSWORD_FAILURE:
    case GET_QUESTIONNAIRE_INSTANCE_LIST_FAILURE:
    case WATCH_QUESTIONNAIRE_INSTANCE_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    default:
        return state;
    }
}
