import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Form, Modal, Button, Input, DatePicker, Skeleton } from 'antd';
import { keys, isUndefined, isEmpty, uniq, isNaN, isNull, isArray, filter, isNumber, sortBy } from 'underscore';
import moment from 'moment';

import QuestionCategory from '../../components/QuestionCategory';
import MainLayout from '../../layouts/MainLayout';
import Icon from '../../elements/CustomSVGIcon';
import './style.scss';
import * as questionnaireActions from '../../core/questionnaire/questionnaireActions';

const { TextArea } = Input;

const QuestionnairePage = () => {
    // State
    const [renderReminderModal, setRenderReminderModal] = useState(false);
    const [questionToRemind, setQuestionToRemind] = useState(null);

    const [isLoading, setIsLoading] = useState(true);

    const [renderAlarmModal, setRenderAlarmModal] = useState(false);
    const [shouldSaveQuestionnaire, setShouldSaveQuestionnaire] = useState(false);
    const [progress, setProgress] = useState(0);
    const [questionCount, setQuestionCount] = useState(false);
    const [questionsAnsweredCount, setQuestionsAnsweredCount] = useState();
    const [stateCategories, setStateCategories] = useState(null);
    const [stateSortedCategories, setSortedStateCategories] = useState(null);

    // eslint-disable-next-line prefer-const
    let [answers, setAnswers] = useState(null);
    const [updatedAnswers, setUpdatedAnswers] = useState(null);
    const [answersUpdatedVersion, setAnswersUpdatedVersion] = useState(0);
    const [allUserAnswers, setAllUserAnswers] = useState(null);
    const [optionalDependencyAnswers, setOptionalDependencyAnswers] = useState([]);
    const [startTime, setStartTime] = useState(null);
    const [reminderState, setReminderState] = useState(null);

    // Redux
    const { questionnaire, isFetching } = useSelector(state => state.questionnaire);

    const categories = questionnaire?.questionnaire?.question_category;
    const { response, reminders, companyQuestionnaireInstance } = questionnaire;
    const { userData } = useSelector(state => state.user);
    const { company } = userData;
    const companyId = company?.id;

    const questionnaireId = questionnaire?.companyQuestionnaireInstance?.id;

    const dispatch = useDispatch();

    const { companyQuestionnaireId } = useParams();
    // eslint-disable-next-line no-undef
    const decryptedCompanyQuestionnaireId = atob(companyQuestionnaireId);

    const getQuestionCount = (cats) => {
        const ungroupedAnswers = [];
        let groupedAnswersCount = 0;
        let ungroupedAnswersCount = 0;
        // only count question that don't have dependend questions
        // and then update the questionCount state only if dependent option value is selected

        const optionalAnswerDependencies = [];

        const groups = [];
        cats.forEach(category => {
            category.questions.forEach((cq) => {
                if (!cq.dependent_on_question) {
                    cq.answers.forEach(a => {
                        if (a.optional) {
                            if (a.has_optional_conditions) {
                                optionalAnswerDependencies.push({
                                    answer_id: a.id,
                                    dependencies: a.optional_condition_answers.split(','),
                                });
                                if (a.group_by) {
                                    groups.push(a.group_by);
                                } else {
                                    ungroupedAnswers.push(a);
                                }
                            }
                        } else {
                            // eslint-disable-next-line
                            if (a.group_by) {
                                groups.push(a.group_by);
                            } else {
                                ungroupedAnswers.push(a);
                            }
                        }
                    });
                }
            });
        });

        ungroupedAnswersCount = ungroupedAnswers.length;

        groupedAnswersCount = uniq(groups).length;

        const totalAnswersNeeded = groupedAnswersCount + ungroupedAnswersCount;
        setQuestionCount(totalAnswersNeeded);
        setOptionalDependencyAnswers(uniq(optionalAnswerDependencies));
        return totalAnswersNeeded;

    };

    const getRemainingPercentage = (addonQuestions) => {
        const totalQuestion = !isUndefined(addonQuestions) ? questionCount + addonQuestions : questionCount;
        let percentage = Math.round((questionsAnsweredCount / totalQuestion) * 100);
        percentage = percentage > 100 ? 100 : percentage;
        setProgress(percentage);
    };

    const calculateQuestionsAnswered = (data) => {
        const answersArray = data;
        let groupedAnswersCount = 0;
        let ungroupedAnswersCount = 0;
        let answerDependenciesAnswered = 0;

        if (!isEmpty(answersArray)) {
            optionalDependencyAnswers.forEach(optional => {
                if (answersArray.filter(aa => optional.dependencies.includes(String(aa.answer_id))).length > 0) {
                    answerDependenciesAnswered++;
                }
            });

            const groupedAnswers = answersArray.filter(a => a.group_by);
            const ungroupedAnswers = answersArray.filter(a => !a.group_by);

            ungroupedAnswersCount = ungroupedAnswers.length;
            const groupKeys = [];
            groupedAnswers.forEach(ga => {
                if (!groupKeys.includes(ga.group_by)) {
                    groupedAnswersCount++;
                }
            });
        }

        const count = groupedAnswersCount + ungroupedAnswersCount + answerDependenciesAnswered;
        return count;
    };

    const getAlreadyAnsweredCount = () => {
        if (answers) {
            const answersArray = Object.keys(answers).map(key => answers[key]);
            setQuestionsAnsweredCount(calculateQuestionsAnswered(answersArray));
        }
    };

    const updateQuestionsAnswered = () => {
        const existingAnswers = Object.keys(answers).map(key => answers[key]);
        const existingQuestionsAnsweredcount = calculateQuestionsAnswered(existingAnswers);
        const updatedQuestionsAnsweredcount = calculateQuestionsAnswered(updatedAnswers);
        let questionsAnsweredInExistingAndUpdatedCount = 0;

        if (!isNull) {
            updatedAnswers.forEach(ua => {
                if (existingAnswers.find(a => a.question_id === ua.question_id)) {
                    questionsAnsweredInExistingAndUpdatedCount++;
                }
            });
        }

        const endCount = (existingQuestionsAnsweredcount + updatedQuestionsAnsweredcount) - questionsAnsweredInExistingAndUpdatedCount;

        setQuestionsAnsweredCount(endCount);
    };

    const mapExistingAnswersToQuestions = (updatedResponse) => {

        setIsLoading(true);

        if (!isEmpty(updatedResponse)) {
            answers = updatedResponse;
        }

        const newCategories = [];
        const answerKeys = keys(answers).map(x => +x);
        if (answers || categories) {
            categories.forEach(c => {
                if (c.questions.length > 0) {
                    // only display category that have questions
                    newCategories[c.id] = {
                        id: c.id,
                        questionnaire_id: c.questionnaire_id,
                        status: c.status,
                        subtitle: c.subtitle,
                        title: c.title,
                        order: c.order,
                    };
                    newCategories[c.id].questions = [];
                    c.questions.forEach(cq => {
                        const reminder = Object.keys(reminders).map(key => reminders[key]).find(r => +r.question_id === +cq.id);
                        newCategories[c.id].questions[cq.id] = {
                            dependency: cq.dependency,
                            dependent_on_question: cq.dependent_on_question,
                            dependent_question_value: cq.dependent_question_value,
                            id: cq.id,
                            question_type: cq.question_type,
                            question_type_name: cq.question_type_name,
                            region_id: cq.region_id,
                            risk_impact_score: cq.risk_impact_score,
                            status: cq.status,
                            subtext: cq.subtext,
                            text: cq.text,
                            reminder: reminder || null,
                            order: cq.pivot.order,
                        };
                        if (cq.answers.length > 0) {
                            newCategories[c.id].questions[cq.id].answers = [];
                            cq.answers.forEach(a => {
                                if (answerKeys.includes(a.id)) {
                                    const data = {
                                        ...a,
                                        value: answers[a.id].value || null,
                                        media_value: answers[a.id].media_value || null,
                                        numeric_value: answers[a.id].numeric_value || null,
                                        final_answer_value: answers[a.id].final_answer_value || null,
                                        multi_value: answers[a.id].multi_value || null,
                                        question_id: cq.id,
                                        category_id: c.id,
                                    };
                                    newCategories[c.id].questions[cq.id].answers.push(data);
                                } else {
                                    newCategories[c.id].questions[cq.id].answers.push({
                                        ...a,
                                        question_id: cq.id,
                                        category_id: c.id,
                                    });
                                }
                            });
                        }
                    });
                }
            });
        }

        const sortedCat = sortBy(newCategories, 'order');

        const sortedArr = [];

        // eslint-disable-next-line array-callback-return
        sortedCat.map((cat) => {
            if (!isEmpty(cat)) {
                sortedArr.push(cat);
            }
        });

        setSortedStateCategories(sortedArr);
        setStateCategories(newCategories);
        setTimeout(() => {
            setIsLoading(false);
        }, 500);

    };

    // eslint-disable-next-line consistent-return
    const updateAnswer = (e, categoryId, questionId, answerType, answerId, fieldName, optional) => {

        let value = null;
        let numeric_value = null;
        let media_value = null;
        let answer_id = null;
        let field_name = null;
        let multi_value = [];

        switch (answerType) {
        case 1:
        case 3:
        case 7:
            // text input
            // text area input
            // email input
            value = e.target.value;
            answer_id = answerId;
            field_name = fieldName;
            break;
        case 2:
            // number input
            value = e;
            answer_id = answerId;
            field_name = fieldName;
            break;
        case 4:
            // select  // this is done intentionally to prevent dublicate rows
            answer_id = e;
            field_name = fieldName;
            numeric_value = e;
            break;
        case 5:
            // file upload
            media_value = e?.status === 'removed' ? '' : e?.response?.id;
            answer_id = answerId;
            field_name = fieldName;
            break;
        case 6:
            // date input
            value = e.format('YYYY-MM-DD HH:mm:ss');
            answer_id = answerId;
            field_name = fieldName;
            break;

        case 8:
            // radio input  // this is done intentionally to prevent dublicate rows
            answer_id = e.target.value;
            field_name = fieldName;
            numeric_value = e.target.value;
            break;
        case 9:
            // multi select
            answer_id = e;
            field_name = fieldName;
            multi_value = e;

            break;
        default:
            return null;
        }

        const data = {
            category_id: categoryId,
            question_id: questionId,
            answer_id,
            value,
            numeric_value,
            media_value,
            field_name,
            multi_value,
            user_id: userData?.id,
            optional,
        };
        const newAnswers = updatedAnswers || [];

        const existingAnswer = newAnswers.findIndex(a => a.field_name === field_name);

        // Check if updated answer is radio/select type and been previously answered
        if (newAnswers.find(a => a.field_name === field_name)) {
            if (answerType === 9) {
                newAnswers[existingAnswer] = data;
            } else {
                newAnswers[existingAnswer] = data;
            }
        } else {
            newAnswers.push(data);
        }

        setAnswersUpdatedVersion(answersUpdatedVersion + 1);

        setUpdatedAnswers(newAnswers);

        updateQuestionsAnswered();
    };

    const saveQuestionnaire = (e) => {
        e.preventDefault();
        const data = {
            companyId: +companyId,
            questionnaireId: +questionnaireId,
            companyQuestionnaireId: +decryptedCompanyQuestionnaireId,
            response: updatedAnswers,
            startTime,
            progress,
        };
        if (data != null) {
            dispatch(questionnaireActions.saveQuestionnaireRequest(data));
        }
    };

    const submitQuestionnaire = () => {

        const data = {
            companyId: +companyId,
            questionnaireId: +questionnaireId,
            companyQuestionnaireId: +decryptedCompanyQuestionnaireId,
            response: updatedAnswers,
            startTime,
            progress,
            submit: 1,
        };

        dispatch(questionnaireActions.submitQuestionnaireRequest(data));
    };

    const onFinishQuestionaire = (values) => {
        if (shouldSaveQuestionnaire) {
            setShouldSaveQuestionnaire(false);
            saveQuestionnaire(values);
        } else {
            submitQuestionnaire(values);
        }
    };

    function toggleReminderModal() {
        setRenderReminderModal(!renderReminderModal);
    }

    function toggleAlarmModal() {
        setRenderAlarmModal(!renderAlarmModal);
    }

    const updateReminder = (values) => {
        const data = {
            company_questionnaire_id: decryptedCompanyQuestionnaireId,
            question_id: questionToRemind,
            questionnaire_id: questionnaireId,
            set_time: moment(values.rem_date).format('YYYY-MM-DD'),
            note: values.rem_text,
        };

        dispatch(questionnaireActions.updateReminderRequest(data));
    };

    const removeReminder = (reminder) => {
        dispatch(questionnaireActions.removeReminderRequest({ questionId: reminder?.question_id, reminderId: reminder?.id, questionnaireId, company_questionnaire_id: decryptedCompanyQuestionnaireId }));
        setReminderState(null);
    };

    function unsetStateCompletely() {
        questionnaire.response = {};
    }

    const backLink = {
        onClick: { unsetStateCompletely },
        link: '/your-questionnaires',
        title: 'Export Risk Audit',
        subtitle: startTime || null,
    };

    function convertStringToArray(res) {
        // convert res to array
        return !isUndefined(res) ? res.split(',') : [];
    }

    const dependantQuestionCount = (v) => {

        let counter = v || 0;

        if (isNull(updatedAnswers)) {
            return counter;
        }
        if (stateCategories) {
            stateCategories.forEach(cat => {
                cat.questions.forEach(ques => {
                    if (ques?.dependency === 1) {
                        const dependencyAnswered = updatedAnswers.find(a => +a.question_id === +ques.dependent_on_question);
                        if (dependencyAnswered?.numeric_value && updatedAnswers.find(a => +a.numeric_value === +ques.dependent_question_value)) {
                            counter++;
                        }

                        if (dependencyAnswered?.multi_value && dependencyAnswered.multi_value) {

                            const multiValues = !isArray(dependencyAnswered.multi_value) ? convertStringToArray(dependencyAnswered.multi_value) : dependencyAnswered.multi_value;

                            const filterVal = filter(multiValues, (val) => +val === +ques.dependent_question_value);
                            if (filterVal.length > 0) {
                                counter++;
                            }
                        }
                    }
                });
            });
        }

        return counter;
    };

    // On mount
    useEffect(() => {
        setStateCategories(null);
        dispatch(questionnaireActions.getQuestionnaireRequest({ companyId, companyQuestionnaireId: decryptedCompanyQuestionnaireId }));
    }, []);

    useEffect(() => {
        if (categories) {
            getQuestionCount(categories);
        }
    }, [categories]);

    useEffect(() => {
        if (response) {
            setAnswers(response);
            mapExistingAnswersToQuestions(response);
            getAlreadyAnsweredCount();
            const addonQuestions = dependantQuestionCount(0);
            getRemainingPercentage(addonQuestions);
        }
        if (companyQuestionnaireInstance) {
            if (companyQuestionnaireInstance.start_time) {
                setStartTime(companyQuestionnaireInstance.start_time);
            } else {
                setStartTime(moment().format('YYYY-MM-DD H:m:s'));
            }
        }
    }, [questionnaire.response, companyQuestionnaireInstance]);

    useEffect(() => {
        // always set the answers
        if (answers) {
            const data = Object.keys(answers).map(key => answers[key]);
            setAllUserAnswers(data);
        }

    }, [answers, categories, stateCategories, isFetching]);

    useEffect(() => {
        // if user response answer is updated
        if (updatedAnswers) {
            let data = [];
            updateQuestionsAnswered();

            if (!isUndefined(updatedAnswers)) {
                updatedAnswers.forEach(e => {

                    // update answer which is already in answer State with new updated value new selected value
                    const answerToBeUpdated = filter(answers, (a) => a?.field_name === e?.field_name);

                    // if answer type is SELECT/RADIO and answer already exist replace value
                    if (!isUndefined(answerToBeUpdated) && isArray(answerToBeUpdated) && answerToBeUpdated.length > 0) {
                        answerToBeUpdated.forEach(val => {
                            if (!isUndefined(val) && !isNull(val.numeric_value)) {
                                // assign new numeric value to answer
                                answers[e.numeric_value] = { ...e };

                                // remove old selected value
                                delete answers[val.answer_id];

                            } else if (!isUndefined(val) && isArray(val?.multi_value)) {
                                // remove old answer
                                const ansNewID = isArray(e.answer_id) ? +e.answer_id[0] : +e.answer_id;

                                if (isUndefined(ansNewID)) {
                                    delete answers[val?.answer_id];
                                }
                                // empty array just delete answer
                                if (isEmpty(e.answer_id)) {
                                    delete answers[val?.answer_id];
                                }

                                if (isNumber(ansNewID) && !isNaN(ansNewID)) {

                                    if (+val.answer_id !== +ansNewID) {
                                        delete answers[val.answer_id];
                                    }

                                    answers[ansNewID] = { ...e };
                                }
                            }
                        });
                    }
                });
            }

            data = updatedAnswers.concat(Object.keys(answers).map(key => answers[key]));
            setAllUserAnswers(data);
        }
    }, [updatedAnswers, answersUpdatedVersion]);

    useEffect(() => {
        const addonQuestions = dependantQuestionCount(0);
        getRemainingPercentage(addonQuestions);
    }, [questionsAnsweredCount, answersUpdatedVersion]);

    useEffect(() => {
        mapExistingAnswersToQuestions();
        getAlreadyAnsweredCount();
    }, [questionnaire.reminders]);

    useEffect(() => {
        if (!isEmpty(questionnaire?.reminders)) {
            const reminderInst = !isUndefined(questionnaire?.reminders[questionToRemind]) ? questionnaire.reminders[questionToRemind] : null;
            setReminderState(reminderInst);
        }
    }, [questionToRemind]);

    return (
        <MainLayout
            title={<div>Welcome</div>}
            backLink={backLink}
            className=""
            floatingHeader=""
            headerTitle=""
            hideProgressBar
            toolbar={[
                { id: '002', label: 'Save', iconName: 'save', form: 'questionnaire-form', key: 'submit', htmlType: 'submit', onClick: (e) => { setShouldSaveQuestionnaire(true); saveQuestionnaire(e); } },
                { id: '001', label: 'Submit', iconName: 'send', form: 'questionnaire-form', key: 'submit', onClick: () => toggleAlarmModal() },
            ]}
            progress={isNaN(progress) ? 0 : progress}>
            <div className="container">
                <div className="form-quiz">
                    {isFetching && isLoading ? <Skeleton />
                        : (
                            <Form
                                name="questionnaire"
                                layout="vertical"
                                onFinish={onFinishQuestionaire}
                                id="questionnaire-form"
                                scrollToFirstError>
                                {!isLoading && stateSortedCategories && stateSortedCategories.map((category) => (
                                    <QuestionCategory
                                        key={category.id}
                                        updateAnswer={updateAnswer}
                                        questionToRemind={questionToRemind}
                                        category={category}
                                        toggleReminderModal={toggleReminderModal}
                                        setQuestionToRemind={setQuestionToRemind}
                                        removeReminder={removeReminder}
                                        convertStringToArray={convertStringToArray}
                                        allUserAnswers={allUserAnswers} />
                                )) }
                                <div className="blocks__footer form-quiz__footer">
                                    <Button onClick={(e) => { setShouldSaveQuestionnaire(true); saveQuestionnaire(e); }} htmlType="submit" type="primary">
                                        <Icon name="save" />
                                        <span>Save</span>
                                    </Button>

                                    {progress !== 100 ? (
                                        <Button form="questionnaire-form" htmlType="submit" type="primary" className="btn-green" onClick={toggleAlarmModal}>
                                            <Icon name="send" />
                                            <span>Submit for Review</span>
                                        </Button>
                                    ) : (
                                        <Button type="primary" className="btn-green" onClick={toggleAlarmModal}>
                                            <Icon name="send" />
                                            <span>Submit for Review</span>
                                        </Button>
                                    )}
                                </div>
                                <Modal
                                    centered
                                    width={607}
                                    footer={null}
                                    visible={renderAlarmModal}
                                    onCancel={toggleAlarmModal}
                                    closeIcon={<Icon name="close" />}
                                    className="modal-custom">
                                    <div className="text-center ">
                                        {progress !== 100 ? (
                                            <>
                                                <Icon name="alarm" />
                                                <h4 className="h-5 modal-custom__title mt-1">Questionnaire Not Complete</h4>
                                                <p className="mt-1">You must complete this questionnaire before submitting</p>
                                            </>
                                        ) : (
                                            <>
                                                <h4 className="h-5 modal-custom__title mt-1">Submit questionnaire</h4>
                                                <p className="mt-1">If your answers are final you can now submit your questionnaire</p>
                                            </>
                                        )}

                                        <div className="mt-5">
                                            {progress !== 100 ? (
                                                <>
                                                    <Button onClick={toggleAlarmModal} type="primary" size="large" className="btn btn--ok">
                                                        Ok
                                                    </Button>
                                                    <br />
                                                    <Button onClick={onFinishQuestionaire} key="submit" type="link" size="large" className="mt-1">
                                                        Submit anyway
                                                    </Button>
                                                </>
                                            ) : (
                                                <>
                                                    <Button form="questionnaire-form" htmlType="submit" type="primary" size="large" className="btn btn--ok">
                                                        Submit
                                                    </Button>
                                                </>
                                            )}

                                        </div>
                                    </div>
                                </Modal>
                            </Form>
                        )}

                </div>
            </div>
            <Modal
                centered
                width={607}
                footer={null}
                visible={renderReminderModal}
                onCancel={toggleReminderModal}
                closeIcon={<Icon name="close" />}
                destroyOnClose
                className="modal-§custom">
                <div className="form-wrap">
                    <h3 className="h-3 modal-custom__title text-center">Set reminder</h3>
                    <Form
                        onFinish={updateReminder}
                        name="reminder"
                        layout="vertical"
                        className="mt-2"
                        requiredMark={false}>
                        <Form.Item
                            label="Reminder date"
                            name="rem_date"
                            rules={[{ required: true, message: 'Please provide the date' }]}>
                            <DatePicker
                                placeholder="12-03-2021"
                                style={{ width: '100%' }}
                                format="DD-MM-YYYY"
                                defaultValue={moment(reminderState?.set_time)}
                                size="large"
                                suffixIcon={<Icon name="calendar" />} />
                        </Form.Item>

                        <Form.Item
                            label="Reminder text"
                            name="rem_text"
                            initialValue={reminderState?.note}
                            rules={[{ required: true, message: 'Please provide the text' }]}>
                            <TextArea size="large"
                                value={reminderState?.note}
                                placeholder="Enter your text" />
                        </Form.Item>

                        <Form.Item>
                            <div className="text-center mt-3">
                                <Button htmlType="submit" type="primary" size="large" className="btn primary btn--ok">
                                    Save Reminder
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>

        </MainLayout>
    );
};

export default QuestionnairePage;
