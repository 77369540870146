import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Form, Input, Button } from 'antd';

import { resetPasswordRequest } from '../../core/user/userActions';
import MainLayout from '../../layouts/MainLayout';

const ResetPassword = ({ match }) => {
    const dispatch = useDispatch();

    const onFinish = ({ email, newPassword, confirmPassword }) => {
        const { token } = match.params;
        dispatch(resetPasswordRequest(
            {
                email,
                newPassword,
                confirmPassword,
                token,
            },
        ));
    };

    const backLink = {
        link: '/',
        title: '',
    };

    return (
        <MainLayout
            className="resetPassword"
            floatingHeader
            backLink={backLink}
            hideProgressBar
            title={<div>Reset Password</div>}
            showHeader={false}
            headerTitle={<div>Reset Password</div>}>
            <div className="block">
                <div className="block__body">
                    <Form onFinish={onFinish} layout="horizontal">
                        <Row>
                            <Col sm={18} md={12} lg={8} xl={6} style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                <Form.Item label="Email" name="email" rules={[{ type: 'email', required: true }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label="New Password" name="newPassword" rules={[{ required: true }]}>
                                    <Input.Password />
                                </Form.Item>
                                <Form.Item label="Confirm Password"
                                    name="confirmPassword"
                                    dependencies={['newPassword']}
                                    rules={[
                                        { required: true },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('newPassword') === value) {
                                                    return Promise.resolve();
                                                }

                                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                            },
                                        }),
                                    ]}>
                                    <Input.Password />
                                </Form.Item>
                                <Row>
                                    <Col xs={24}>
                                        <Button type="primary" htmlType="submit" className="wide">
                                            Reset Password
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                    </Form>
                </div>
            </div>
        </MainLayout>
    );
};

ResetPassword.propTypes = {
    match: PropTypes.object.isRequired,
};

export default ResetPassword;
