import React from 'react';
import PropTypes from 'prop-types';

import { CoreRouter, LogoutRoute, DefaultRoute, AuthRoute } from './utils/coreRouter'; // AuthRoute
import LoginPage from '../pages/LoginPage';
import ResetPassword from '../pages/ResetPassword';
import YourQuestionnairesPage from '../pages/YourQuestionnairesPage';
import QuestionnairePage from '../pages/QuestionnairePage';
import RiskScorePage from '../pages/RiskScorePage';

const Routes = ({ persistor }) => (
    <CoreRouter>
        {/* Routes without authentication */}
        <DefaultRoute exact path="/" component={LoginPage} />
        <AuthRoute exact path="/your-questionnaires" component={YourQuestionnairesPage} />
        <AuthRoute exact path="/questionnaire/:companyQuestionnaireId" component={QuestionnairePage} />
        <AuthRoute exact path="/risk-score" component={RiskScorePage} />
        <DefaultRoute exact path="/password/reset/:token" component={ResetPassword} />

        {/* Logout route */}
        <LogoutRoute exact path="/logout" persistor={persistor} />
    </CoreRouter>
);

Routes.defaultProps = {
    persistor: null,
};

Routes.propTypes = {
    persistor: PropTypes.object,
};

export default Routes;
