import React from 'react';
import PropTypes from 'prop-types';
import { filter, isArray, isString } from 'underscore';

import Question from '../../elements/Question';

import './style.scss';

function QuestionCategory(props) {
    const { category, toggleReminderModal, updateAnswer, setQuestionToRemind, removeReminder, allUserAnswers, convertStringToArray } = props;

    // byDefault we hiding all category
    let dependentCategory = 1;
    let multiValues = null;

    function dependencyReturn(multiValue, e) {

        const filterVal = filter(multiValue, (val) => +val === +e.dependent_question_value);
        if (filterVal.length > 0) {
            dependentCategory = 0;
        }
        return dependentCategory;
    }

    // check if category has only dependent questions
    const checkIfDependent = () => {

        if (category?.questions.length > 0) {
            category.questions.forEach(e => {

                // we have found that there is question that is dependent on this category hence show this category
                if (e.dependency === 0 || (allUserAnswers.length > 0 && allUserAnswers.find(a => +a.answer_id === +e.dependent_question_value))) {
                    dependentCategory = 0;
                    return dependentCategory;
                }
                // if dependency is 1 check if value exist in answer
                if (e.dependency === 1) {
                    if (allUserAnswers.length > 0) {
                        const dependencyAnswered = allUserAnswers.find(a => +a.question_id === +e.dependent_on_question);

                        if (isArray(dependencyAnswered?.multi_value)) {
                            multiValues = dependencyAnswered.multi_value;
                        } else if (isString(dependencyAnswered?.multi_value)) {
                            multiValues = convertStringToArray(dependencyAnswered.multi_value);
                        }
                        return dependencyReturn(multiValues, e);
                    }
                    return dependentCategory;
                }
                return dependentCategory;
            });
        }

        return dependentCategory;
    };

    return (

        <>
            {checkIfDependent() === 1 ? ''
                : (
                    <div className="block" key={category?.id}>
                        <div className="block__header">
                            <h3 className="h-3">{category.title}</h3>
                        </div>
                        <div className="block__body">
                            {category.questions && category.questions.sort((a, b) => a.order - b.order).map(question => (
                                <Question
                                    key={question?.id}
                                    updateAnswer={updateAnswer}
                                    question={question}
                                    toggleReminderModal={toggleReminderModal}
                                    setQuestionToRemind={setQuestionToRemind}
                                    removeReminder={removeReminder}
                                    convertStringToArray={convertStringToArray}
                                    allUserAnswers={allUserAnswers} />
                            ))}
                        </div>
                    </div>
                )}

        </>

    );
}

QuestionCategory.defaultProps = {
    allUserAnswers: [],
    sortedCategories: [],
};
QuestionCategory.propTypes = {
    category: PropTypes.object.isRequired,
    sortedCategories: PropTypes.array,
    updateAnswer: PropTypes.func.isRequired,
    toggleReminderModal: PropTypes.any.isRequired,
    setQuestionToRemind: PropTypes.func.isRequired,
    removeReminder: PropTypes.func.isRequired,
    allUserAnswers: PropTypes.array,
    convertStringToArray: PropTypes.func.isRequired,
};

export default QuestionCategory;
