import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Progress } from 'antd';
// import { useSelector } from 'react-redux';

import Icon from '../../elements/CustomSVGIcon';
import './style.scss';

function ProgressHeader(props) {
    const { toolbar, backLink, progress, floating, customClass } = props;

    return (
        <header className={`header ${floating ? 'floating-header' : ''}`}>
            <div className="header__inner">
                <Row type="flex" justify="space-between" align="middle" gutter={16}>
                    <Col>
                        <Row type="flex" align="middle" className="header__topline">
                            <div className="backlink">
                                <Link to={backLink.link}>
                                    <Icon name="chevron-left" />
                                </Link>
                                <h6>
                                    <span>{backLink.title}</span> <br />
                                    <span>{backLink.subtitle}</span>
                                </h6>
                            </div>
                        </Row>
                    </Col>
                    <Col flex="auto" xs={{ order: 3 }} md={{ order: 2 }} className={`header__middle-col  ${customClass}`}>
                        <div className="progress-wrap">
                            <p className="progress__caption">{progress}% Answered</p>
                            <Progress
                                className="progress-custom"
                                trailColor="#fff"
                                percent={progress}
                                showInfo={false}
                                strokeColor="#20aae4" />
                        </div>
                    </Col>

                    <Col xs={{ order: 2 }} md={{ order: 3 }}>
                        <div className="toolbar">
                            {toolbar.map((button, buttonIndex) => (
                                <Button
                                    key={button.id || buttonIndex}
                                    size={button.size || 'large'}
                                    type="text"
                                    className="toolbar-button transparent"
                                    href={button.link}
                                    form={button.form}
                                    htmlType={button.htmlType}
                                    onClick={button.onClick || null}>
                                    <Icon name={button.iconName} />
                                    <span>{button.label}</span>
                                </Button>
                            ))}
                        </div>
                    </Col>
                </Row>
            </div>
        </header>
    );
}

ProgressHeader.defaultProps = {
    toolbar: [],
};
ProgressHeader.propTypes = {
    backLink: PropTypes.shape({
        link: PropTypes.any,
        subtitle: PropTypes.any,
        title: PropTypes.any,
    }).isRequired,
    floating: PropTypes.any.isRequired,
    progress: PropTypes.any.isRequired,
    toolbar: PropTypes.any,
    customClass: PropTypes.any.isRequired,
};

// ProgressHeader.defaultProps = {
//     toolbar: [],
//     backLink: null,
//     floating: false,
//     progress: null,
// };

// ProgressHeader.propTypes = {
//     toolbar: PropTypes.array,
//     backLink: PropTypes.object,
//     floating: PropTypes.bool,
//     progress: PropTypes.number,
//     actions: PropTypes.object.isRequired,
// };

// function mapStateToProps(state, ownProps) {
//     return {
//         ...ownProps,
//         user: state.user.userData,
//     };
// }

// function mapDispatchToProps(dispatch) {
//     return {
//         actions: bindActionCreators(
//             {
//                 ...userActions,
//             },
//             dispatch,
//         ),
//     };
// }
export default ProgressHeader;
