/* eslint no-multiple-empty-lines: "error" */
import { Record } from 'immutable';
import { object } from 'underscore';

const {
    GET_QUESTIONNAIRES_DATA_REQUEST,
    GET_QUESTIONNAIRES_DATA_SUCCESS,
    GET_QUESTIONNAIRES_DATA_FAILURE,

    // company questionnaire media
    QUESTIONNAIRES_MEDIA_REQUEST,
    QUESTIONNAIRES_MEDIA_SUCCESS,
    QUESTIONNAIRES_MEDIA_FAILURE,

    // copmany questionnaire response
    QUESTIONNAIRES_RESPONSE_REQUEST,
    QUESTIONNAIRES_RESPONSE_SUCCESS,
    QUESTIONNAIRES_RESPONSE_FAILURE,
} = require('./questionnairesActions').constants;

const {
    LOGOUT_SUCCESS,
} = require('../user/userActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    questionnaireList: object,
    questionnairesMediaList: object,
    questionnairesResponse: object,
    question: {},
});

const initialState = new InitialState();

/**
 * ## Questionnaire Reducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function questionnaireReducer(state = initialState, { payload, type }) {
    // eslint-disable-next-line no-param-reassign
    if (!(state instanceof InitialState)) state = initialState.mergeDeep(state);

    switch (type) {

    case LOGOUT_SUCCESS:
        return state.set('questionnaireList', object)
            .set('questionnairesMediaList', object)
            .set('questionnairesResponse', object);

    case GET_QUESTIONNAIRES_DATA_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case GET_QUESTIONNAIRES_DATA_SUCCESS:
        return state.set('isFetching', false)
            .set('error', null)
            .set('questionnaireList', payload);

    case GET_QUESTIONNAIRES_DATA_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    // questionnaires media
    case QUESTIONNAIRES_MEDIA_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case QUESTIONNAIRES_MEDIA_SUCCESS:
        return state.set('isFetching', false)
            .set('error', null)
            .set('questionnairesMediaList', payload);

    case QUESTIONNAIRES_MEDIA_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    // questionnire answers
    case QUESTIONNAIRES_RESPONSE_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case QUESTIONNAIRES_RESPONSE_SUCCESS:
        return state.set('isFetching', false)
            .set('error', null)
            .set('questionnairesResponse', payload);

    case QUESTIONNAIRES_RESPONSE_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    default:
        return state;
    }
}
