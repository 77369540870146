import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, Skeleton } from 'antd';
import { isEmpty, isUndefined } from 'underscore';

import moment from 'moment';
import Questionnaire from '../../components/Questionnaire';
import AnswerCategory from '../../components/AnswerCategory';
import FileGroup from '../../components/FileGroup';
import MainLayout from '../../layouts/MainLayout';
import Icon from '../../elements/CustomSVGIcon';
import './style.scss';

import * as questionnaireActions from '../../core/questionnaires/questionnairesActions';

function YourQuestionnairesPage() {

    // State
    const [renderFilesModal, setRenderFilesModal] = useState(false);
    const [renderAnswersModal, setRenderAnswersModal] = useState(false);
    const [modalQuestionnaire, setModalQuestionnaire] = useState(false);

    // Redux
    const userInstance = useSelector(state => state.user.userData);

    // questionnaires list
    const questionnaireInstance = useSelector(state => state.questionnaires.questionnaireList);

    // questionnaire media list
    const questionnaireMedia = useSelector(state => state.questionnaires.questionnairesMediaList);

    // questionnaire responses
    const questionnaireResponse = useSelector(state => state.questionnaires.questionnairesResponse);

    const questionnaireInstanceModelList = questionnaireInstance?.questionnaires;

    const dispatch = useDispatch();

    // On mount
    useEffect(() => {
        dispatch(questionnaireActions.getQuestionnairesDataRequest({ companyId: userInstance?.company?.id }));
    }, []);

    // toggle modal
    function toggleFilesModal(questionnaireInstanceArg) {
        // if state is true only then call api
        if (!renderFilesModal) {
            // pre set the questionnaire for modal
            setModalQuestionnaire(questionnaireInstanceArg);
            dispatch(questionnaireActions.questionnairesMediaRequest({ companyId: userInstance?.company?.id, companyQuestionnaireId: questionnaireInstanceArg.pivot.id }));
        }

        setRenderFilesModal(!renderFilesModal);
    }

    function toggleAnswersModal(questionnaireInstanceArg) {

        // if state is true only then call api
        if (!renderAnswersModal) {
            // pre set the questionnaire for modal
            setModalQuestionnaire(questionnaireInstanceArg);
            dispatch(questionnaireActions.questionnairesResponseRequest({ companyId: userInstance?.company?.id, questionnaireId: questionnaireInstanceArg?.id, companyQuestionnaireId: questionnaireInstanceArg.pivot.id }));
        }

        setRenderAnswersModal(!renderAnswersModal);

    }

    function decodeImg(img) {
        return `${process.env.REACT_APP_API_URL}/api/company/${userInstance?.company?.id}/force-download?img=${Buffer.from(img).toString('base64')}`;
    }

    // get all the files for questionnaire
    function setAllFiles(args) {
        const files = [];
        if (args !== undefined) {
            args.forEach(element => {
                files.push({
                    id: element.id,
                    cols: [
                        { value: element.title, link: decodeImg(element.src) },
                        { value: moment(element.uploaded_at).format('DD-MM-YYYY h:mm:ss') },
                        { value: 'Download', link: decodeImg(element.src) },
                    ],
                });
            });
        }

        return files;
    }

    const fileGroupList = [
        {
            id: '0001',
            title: 'Added by Hayes Export Controls',
            fileList: setAllFiles(questionnaireMedia?.adminUpload),
        },
        {
            id: '0002',
            title: 'Added by you',
            fileList: setAllFiles(questionnaireMedia?.userUpload),
        },
    ];

    function setResponses(answers) {
        const questionResponsesArr = questionnaireResponse.response;

        const ansArr = [];
        if (answers !== undefined) {
            answers.forEach(el => {
                if (el.id in questionResponsesArr) {
                    ansArr.push({
                        value: questionResponsesArr[el.id].media_value === null || questionResponsesArr[el.id].media_value === ''
                            ? questionResponsesArr[el.id].final_answer_value : questionResponsesArr[el.id].media_value,
                        link: questionResponsesArr[el.id].media_value === null || questionResponsesArr[el.id].media_value === ''
                            ? '#' : decodeImg(questionResponsesArr[el.id].final_answer_value),
                    });
                }
            });
        }
        return ansArr;
    }

    function setQuestionsAnswers(questions) {
        const responses = [];
        questions.forEach(element => {
            responses.push({
                id: element.id,
                cols: [
                    { value: element.text },
                    { ansvalue: setResponses(element.answers) },
                ],
            });
        });

        return responses;
    }

    const setQuestions = () => {
        const finalArr = [];
        const categoryQuestionsArr = questionnaireResponse?.questionnaire?.question_category;

        if (categoryQuestionsArr !== undefined) {
            categoryQuestionsArr.forEach(element => {
                finalArr.push({
                    id: element?.id,
                    title: element?.title,
                    answerList: setQuestionsAnswers(element?.questions),
                });
            });
        }

        return finalArr;
    };

    const questionnaireAnswerCategoryList = setQuestions();

    return (

        <MainLayout
            title={<div>Welcome</div>}
            logo={<div>Export Control Portal</div>}
            backLink="#"
            className=""
            floatingHeader=""
            progress=""
            toolbar=""
            hideProgressBar
            headerTitle={<div>Your Questionnaires</div>}>
            <div className="container">
                <div className="form-quiz">
                    { isUndefined(questionnaireInstanceModelList)
                        ? <Skeleton />
                        : questionnaireInstanceModelList?.map((questionnaireInstanceModel) => (
                            <Questionnaire key={questionnaireInstanceModel.pivot.id} questionnaireInstanceModel={questionnaireInstanceModel} toggleAnswersModal={toggleAnswersModal} toggleFilesModal={toggleFilesModal} />
                        ))}
                </div>
            </div>
            <Modal
                centered
                width={671}
                footer={null}
                visible={renderAnswersModal}
                onCancel={toggleAnswersModal}
                closeIcon={<Icon name="close" />}
                className="modal-custom">
                <div className="tables-wrap">
                    <div className="tables__header">
                        <h4 className="h-4 title">Your Answers</h4>
                    </div>
                    {questionnaireAnswerCategoryList.map((questionnaireAnswerCategory) => (
                        <AnswerCategory key={questionnaireAnswerCategory.id} questionnaireAnswerCategory={questionnaireAnswerCategory} />
                    ))}
                </div>
            </Modal>
            <Modal
                centered
                width={671}
                footer={null}
                visible={renderFilesModal}
                onCancel={toggleFilesModal}
                closeIcon={<Icon name="close" />}
                className="modal-custom">
                <div className="tables-wrap">
                    <div className="tables__header">
                        <h4 className="h-4 title">Files</h4>
                        <p className="mt-1">
                            {modalQuestionnaire?.title} | {modalQuestionnaire?.pivot?.start_time === null
                                ? 'Not yet Started' : `${moment(modalQuestionnaire?.pivot?.start_time).format('DD-MM-YYYY h:mm:ss')} Started`}
                            | {modalQuestionnaire?.pivot?.completion_time !== null ? 'Submited ' : 'Not yet submitted' }
                        </p>
                    </div>
                    {!isEmpty(fileGroupList)
                        ? fileGroupList.map(fileGroup => (
                            <FileGroup key={fileGroup.id} fileGroup={fileGroup} />
                        ))
                        : (
                            <div className="data-placeholder">
                                <Icon name="files" />
                                <p>No files uploaded to Questionairre</p>
                            </div>
                        )}
                </div>
            </Modal>
        </MainLayout>
    );
}

export default YourQuestionnairesPage;
