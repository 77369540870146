import { call, put, takeEvery, all } from 'redux-saga/effects';
import { message } from 'antd';
import lockr from 'lockr';
import { fetchApi, fetchApiAuth } from '../utils/api';
import history from '../utils/history';

import * as retrieveMockData from '../utils/mockFunctionality/retrieveMockData';

import {
    loginSuccess,
    loginFailure,

    logoutSuccess,
    logoutFailure,

    forgotPasswordSuccess,
    forgotPasswordFailure,

    getQuestionnaireInstanceListSuccess,
    getQuestionnaireInstanceListFailure,

    watchQuestionnaireInstanceSuccess,
    watchQuestionnaireInstanceFailure,

    resetPasswordSuccess,
    resetPasswordFailure,
} from './userActions';

const {
    AUTH_KEY,
    LOCALSTORAGE_EXPIRES_KEY,
} = require('../constants').default;

const {
    LOGIN_REQUEST,
    LOGOUT_REQUEST,
    FORGOT_PASSWORD_REQUEST,
    RESET_PASSWORD_REQUEST,
    GET_QUESTIONNAIRE_INSTANCE_LIST_REQUEST,
    WATCH_QUESTIONNAIRE_INSTANCE_REQUEST,
} = require('./userActions').constants;

function saveSessionToken(action) {
    const authDate = new Date();
    lockr.set(LOCALSTORAGE_EXPIRES_KEY, authDate);
    lockr.set(AUTH_KEY, action.data.token);
}

function* makeLoginRequest(action) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: '/login',
            body: { email: action.payload.username, password: action.payload.password },
        });
        yield call(saveSessionToken, response);
        yield put(loginSuccess(response));
        history.push('/your-questionnaires');
    } catch (e) {
        yield put(loginFailure(e.response ? e.response.data.errors.email : e));
    }
}

function* logoutRequest() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'logout',
            body: {},
        });
        lockr.rm(AUTH_KEY);
        lockr.rm(LOCALSTORAGE_EXPIRES_KEY);
        localStorage.clear();
        yield put(logoutSuccess(response));
        history.push('/');
    } catch (e) {
        yield put(logoutFailure(e.response ? e.response.data.message : e));
    }
}

function* forgotPassword({ payload }) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: 'forgot-password',
            body: { email: payload.email },
        });

        yield put(forgotPasswordSuccess(response));
        message.success('A password reset email has been requested, if an account exists you will receive an email shortly.');
    } catch (e) {
        yield put(forgotPasswordFailure(e.response ? e.response.data.errors.email[0] : e));
    }
}

function* resetPassword({ payload }) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: 'reset-password',
            body: {
                email: payload.email,
                newPassword: payload.newPassword,
                confirmPassword: payload.confirmPassword,
                token: payload.token,
            },
        });

        yield put(resetPasswordSuccess(response));
        message.success('Password updated successfully');
        history.push('/');
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(resetPasswordFailure(e.response ? e.response.data.message : e));
    }
}

// eslint-disable-next-line
function* getQuestionnaireInstanceList(action) {
    try {
        // const response = yield call(fetchApi, {
        //     method: 'POST',
        //     url: '/get-questionnaire-instance-list',
        //     body: action.payload,
        // });

        yield put(getQuestionnaireInstanceListSuccess({ data: retrieveMockData.retrieveQuestionnaireInstanceModelListData() }));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getQuestionnaireInstanceListFailure(e.response ? e.response.data.message : e));
    }
}
// eslint-disable-next-line
function* watchQuestionnaireInstance(action) {
    try {
        // const response = yield call(fetchApi, {
        //     method: 'POST',
        //     url: 'watch-questionnaire-instance',
        //     body: action.payload,
        // });

        yield put(watchQuestionnaireInstanceSuccess({ data: retrieveMockData.retrieveQuestionnaireInstanceModelData() }));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(watchQuestionnaireInstanceFailure(e.response ? e.response.data.message : e));
    }
}

/**
 * Watch actions
 */
export default function* userSaga() {
    yield all([
        takeEvery(LOGIN_REQUEST, makeLoginRequest),
        takeEvery(LOGOUT_REQUEST, logoutRequest),
        takeEvery(FORGOT_PASSWORD_REQUEST, forgotPassword),
        takeEvery(RESET_PASSWORD_REQUEST, resetPassword),
        takeEvery(GET_QUESTIONNAIRE_INSTANCE_LIST_REQUEST, getQuestionnaireInstanceList),
        takeEvery(WATCH_QUESTIONNAIRE_INSTANCE_REQUEST, watchQuestionnaireInstance),
    ]);
}
