import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import './style.scss';

function File(props) {
    const { file } = props;

    return (
        <div
            key={file.id}
            className="table__row"
            style={{ gridTemplateColumns: '13fr 8fr 5fr' }}>
            {file.cols.map((col, idx) => (
                <div key={col.value} className="table__col">
                    <h6 className="table__caption">{['File name', 'Uploaded', ''][idx]}</h6>
                    {col.link ? (
                        <Button type="link" download href={col.link}>
                            {col.value}
                        </Button>
                    ) : (
                        <p>{col.value} </p>
                    )}
                </div>
            ))}
        </div>
    );
}

File.propTypes = {
    file: PropTypes.shape({
        cols: PropTypes.any,
        id: PropTypes.any,
    }).isRequired,
};

export default File;
