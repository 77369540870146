import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'underscore';

import File from '../../elements/File';

import './style.scss';

function FileGroup(props) {
    const { fileGroup } = props;
    return (
        <div key={fileGroup.id} className="table">
            <h5 className="h-5 table__title">{fileGroup.title}</h5>
            <div className="table__head" style={{ gridTemplateColumns: '13fr 8fr 5fr' }}>
                {!isEmpty(fileGroup.fileList) ? ['File name', 'Uploaded', '']?.map(col => (
                    <div key={col} className="table__col">
                        <h6 className="table__caption">{col}</h6>
                    </div>
                )) : ''}
            </div>
            {isEmpty(fileGroup.fileList) ? <span style={{ color: 'red' }}>No files uploaded.</span> : fileGroup.fileList.map(file => (
                <File key={file.id} fileGroup={fileGroup} file={file} />
            ))}
        </div>
    );
}

FileGroup.propTypes = {
    fileGroup: PropTypes.shape({
        fileList: PropTypes.any,
        id: PropTypes.any,
        title: PropTypes.any,
    }).isRequired,
};
export default FileGroup;
