import MockData from './MockData';

const user = MockData;

function retrieveUserProfileData() {
    const buffer = JSON.parse(JSON.stringify(user));
    delete buffer.companyModel;

    return null;
}

function retrieveCompanyModelData() {
    const buffer = JSON.parse(JSON.stringify(user));
    const { companyModel } = buffer || null;
    delete companyModel.questionnaireInstanceModelList;

    return companyModel;
}

function retrieveQuestionnaireInstanceModelListData() {
    const buffer = JSON.parse(JSON.stringify(user));
    const { questionnaireInstanceModelList } = buffer.companyModel || {};

    return questionnaireInstanceModelList;
}

function retrieveQuestionnaireInstanceModelData() {
    const buffer = JSON.parse(JSON.stringify(user));
    const questionnaireInstanceModel = buffer.companyModel.questionnaireInstanceModelList[0];

    return questionnaireInstanceModel;
}

export { retrieveUserProfileData, retrieveCompanyModelData, retrieveQuestionnaireInstanceModelListData, retrieveQuestionnaireInstanceModelData };
