import { Record } from 'immutable';
import { filter, allKeys } from 'underscore';

const {
    GET_QUESTIONNAIRE_REQUEST,
    GET_QUESTIONNAIRE_SUCCESS,
    GET_QUESTIONNAIRE_FAILURE,

    SUBMIT_QUESTIONNAIRE_REQUEST,
    SUBMIT_QUESTIONNAIRE_SUCCESS,
    SUBMIT_QUESTIONNAIRE_FAILURE,

    SAVE_QUESTIONNAIRE_REQUEST,
    SAVE_QUESTIONNAIRE_SUCCESS,
    SAVE_QUESTIONNAIRE_FAILURE,

    UPDATE_REMINDER_REQUEST,
    UPDATE_REMINDER_SUCCESS,
    UPDATE_REMINDER_FAILURE,

    REMOVE_REMINDER_REQUEST,
    REMOVE_REMINDER_SUCCESS,
    REMOVE_REMINDER_FAILURE,

    CLEAR_QUESTIONNAIRE_REQUEST,
    CLEAR_QUESTIONNAIRE_SUCCESS,
    CLEAR_QUESTIONNAIRE_FAILURE,

} = require('./questionnaireActions').constants;

const {
    LOGOUT_SUCCESS,
} = require('../user/userActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    questionnaire: {},
    currentAnswers: [],
    riskScore: null,
});

const initialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function userReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

    switch (type) {

    case LOGOUT_SUCCESS:
        return state.set('questionnaire', {});

    case GET_QUESTIONNAIRE_REQUEST:
    case SUBMIT_QUESTIONNAIRE_REQUEST:
    case SAVE_QUESTIONNAIRE_REQUEST:
    case UPDATE_REMINDER_REQUEST:
    case REMOVE_REMINDER_REQUEST:
    case CLEAR_QUESTIONNAIRE_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case CLEAR_QUESTIONNAIRE_SUCCESS:
        return state.set('isFetching', false).set('questionnaire', {});

    case GET_QUESTIONNAIRE_SUCCESS:
        return state.set('isFetching', false).set('questionnaire', payload);

    case SAVE_QUESTIONNAIRE_SUCCESS: {
        const { questionnaire } = state;

        const payloadKeys = allKeys(payload?.data);

        // remove payload response keys from original uestionnaire response array
        payloadKeys.forEach(el => {
            // unmatched array
            delete questionnaire.response[el];
            questionnaire.response[el] = payload.data[el];
        });
        return state.set('isFetching', false).set('questionnaire', questionnaire);
    }

    case SUBMIT_QUESTIONNAIRE_SUCCESS:
        return state.set('riskScore', payload);

    case GET_QUESTIONNAIRE_FAILURE:
    case SUBMIT_QUESTIONNAIRE_FAILURE:
    case SAVE_QUESTIONNAIRE_FAILURE:
    case UPDATE_REMINDER_FAILURE:
    case REMOVE_REMINDER_FAILURE:
    case CLEAR_QUESTIONNAIRE_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    case UPDATE_REMINDER_SUCCESS: {
        const { questionnaire } = state;
        // get all unmatched remnders
        const newReminder = filter(questionnaire.reminders, r => +r.id !== +payload.id);
        // push new reminder
        newReminder.push(payload);
        questionnaire.reminders = newReminder;
        return state.set('isFetching', false).set('questionnaire', questionnaire);
    }

    case REMOVE_REMINDER_SUCCESS: {
        const newQuestionnaire = state.questionnaire;
        const unRemovedReminder = filter(newQuestionnaire.reminders, r => r.id !== payload?.data?.reminderId);
        newQuestionnaire.reminders = unRemovedReminder;
        return state.set('isFetching', false).set('questionnaire', newQuestionnaire);
    }
    default:
        return state;
    }
}
