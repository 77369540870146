import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Progress } from 'antd';
import { isNull } from 'underscore';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import * as questionnaireActions from '../../core/questionnaire/questionnaireActions';

import Icon from '../../elements/CustomSVGIcon';
import './style.scss';

function Questionnaire(props) {
    const { questionnaireInstanceModel, toggleFilesModal, toggleAnswersModal } = props;
    const history = useHistory();

    const dispatch = useDispatch();

    function calculateNotificationCount(questionnaireInstanceArg) {

        const notificationsCount = questionnaireInstanceArg.reminders;

        return notificationsCount;
    }

    const onClickHandler = () => {
        dispatch(questionnaireActions.clearQuestionnaireRequest());

        setTimeout(() => {
            history.push(`/questionnaire/${Buffer.from(questionnaireInstanceModel?.pivot?.id.toString()).toString('base64')}`);
        }, 500);
    };

    // set the risk score color
    const renderOval = () => {
        if (questionnaireInstanceModel?.pivot?.completion_time !== null && questionnaireInstanceModel?.pivot?.display_risk_score) {

            let bgColor = 'bg-red';

            if (+questionnaireInstanceModel?.pivot?.risk_score <= 25) {
                bgColor = 'bg-green';
            } else if (+questionnaireInstanceModel?.pivot?.risk_score > 25 && +questionnaireInstanceModel?.pivot?.risk_score <= 49) {
                bgColor = 'bg-yellow';
            } else if (+questionnaireInstanceModel?.pivot?.risk_score >= 50 && +questionnaireInstanceModel?.pivot?.risk_score <= 74) {
                bgColor = 'bg-orange';
            }

            return <span title="Risk score" className={`${bgColor} oval`}>{questionnaireInstanceModel?.pivot?.risk_score} </span>;
        }
        return '';
    };

    function renderInfoToolbar(questionnaireInstanceArg) {

        return (
            <div className="info-toolbar">
                <span className={calculateNotificationCount(questionnaireInstanceArg) ? 'c_blue' : ''}>
                    <Icon name="notification" />
                    {calculateNotificationCount(questionnaireInstanceArg) || 0}
                </span>
                <span
                    className={(questionnaireInstanceArg?.mediaFiles === 0) ? '' : 'c_blue'}
                    onClick={() => toggleFilesModal(questionnaireInstanceArg)}>
                    <Icon name="files" />
                    {questionnaireInstanceArg?.mediaFiles || 0} file{(questionnaireInstanceArg?.mediaFiles === 1) ? '' : 's'}
                </span>
                <span
                    className={(questionnaireInstanceArg?.pivot?.view_count > 0) ? 'c_blue' : ''}>
                    {questionnaireInstanceArg?.pivot?.view_count || 0} view{(questionnaireInstanceArg?.pivot?.view_count === 1) ? '' : 's'}
                </span>
            </div>
        );
    }

    return (
        <div key={questionnaireInstanceModel.id} className="block">
            <div className="block__body">
                <div className="block__header2">
                    <h3 className="h-3">{questionnaireInstanceModel.title} {renderOval()} </h3>
                    {renderInfoToolbar(questionnaireInstanceModel)}
                </div>
                <p
                    className={`status ${(questionnaireInstanceModel.pivot.completion_time !== null) ? 'status--submitted' : 'status--pending'
                    }`}>
                    {(questionnaireInstanceModel?.pivot?.completion_time !== null) ? 'Submitted' : 'Pending Submission'}
                </p>

                <Row gutter={[24, { xs: 24, sm: 32, lg: 40 }]} justify="space-between" className="mt-3">
                    <Col xs={24} sm={16}>
                        <p className="fz-14"> {questionnaireInstanceModel?.pivot?.start_time ? ` Started ${moment(questionnaireInstanceModel?.pivot?.start_time).format('DD-MM-YYYY h:mm:ss')}`
                            : ' Not yet started'}

                        </p>
                        <div className="progress-block">
                            <Progress
                                className="progress-custom"
                                trailColor="#fff"
                                percent={questionnaireInstanceModel?.pivot?.progress_percent}
                                showInfo={false}
                                strokeColor="#20aae4" />
                            <span>{questionnaireInstanceModel?.pivot?.progress_percent}% Answered</span>
                        </div>
                    </Col>
                    <Col xs={24} sm={8}>
                        <div className="info-cta">
                            {renderInfoToolbar(questionnaireInstanceModel)}
                            <Button
                                type="default"
                                onClick={(isNull(questionnaireInstanceModel?.pivot?.completion_time))
                                    ? () => { onClickHandler(); }
                                    : () => toggleAnswersModal(questionnaireInstanceModel)}
                                size="large"
                                className="info-cta__btn">
                                {(isNull(questionnaireInstanceModel?.pivot?.completion_time)) ? 'Continue' : 'View'}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

Questionnaire.propTypes = {
    questionnaireInstanceModel: PropTypes.any.isRequired,
    toggleAnswersModal: PropTypes.func.isRequired,
    toggleFilesModal: PropTypes.any.isRequired,
};

export default Questionnaire;
