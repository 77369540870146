import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_QUESTIONNAIRE_REQUEST: null,
    GET_QUESTIONNAIRE_SUCCESS: null,
    GET_QUESTIONNAIRE_FAILURE: null,

    SUBMIT_QUESTIONNAIRE_REQUEST: null,
    SUBMIT_QUESTIONNAIRE_SUCCESS: null,
    SUBMIT_QUESTIONNAIRE_FAILURE: null,

    SAVE_QUESTIONNAIRE_REQUEST: null,
    SAVE_QUESTIONNAIRE_SUCCESS: null,
    SAVE_QUESTIONNAIRE_FAILURE: null,

    UPDATE_REMINDER_REQUEST: null,
    UPDATE_REMINDER_SUCCESS: null,
    UPDATE_REMINDER_FAILURE: null,

    REMOVE_REMINDER_REQUEST: null,
    REMOVE_REMINDER_SUCCESS: null,
    REMOVE_REMINDER_FAILURE: null,

    CLEAR_QUESTIONNAIRE_REQUEST: null,
    CLEAR_QUESTIONNAIRE_SUCCESS: null,
    CLEAR_QUESTIONNAIRE_FAILURE: null,

});

export const {
    getQuestionnaireRequest,
    getQuestionnaireSuccess,
    getQuestionnaireFailure,
} = createActions(
    constants.GET_QUESTIONNAIRE_REQUEST,
    constants.GET_QUESTIONNAIRE_SUCCESS,
    constants.GET_QUESTIONNAIRE_FAILURE,
);

export const {
    submitQuestionnaireRequest,
    submitQuestionnaireSuccess,
    submitQuestionnaireFailure,
} = createActions(
    constants.SUBMIT_QUESTIONNAIRE_REQUEST,
    constants.SUBMIT_QUESTIONNAIRE_SUCCESS,
    constants.SUBMIT_QUESTIONNAIRE_FAILURE,
);

export const {
    saveQuestionnaireRequest,
    saveQuestionnaireSuccess,
    saveQuestionnaireFailure,
} = createActions(
    constants.SAVE_QUESTIONNAIRE_REQUEST,
    constants.SAVE_QUESTIONNAIRE_SUCCESS,
    constants.SAVE_QUESTIONNAIRE_FAILURE,
);

export const {
    updateReminderRequest,
    updateReminderSuccess,
    updateReminderFailure,
} = createActions(
    constants.UPDATE_REMINDER_REQUEST,
    constants.UPDATE_REMINDER_SUCCESS,
    constants.UPDATE_REMINDER_FAILURE,
);

export const {
    removeReminderRequest,
    removeReminderSuccess,
    removeReminderFailure,
} = createActions(
    constants.REMOVE_REMINDER_REQUEST,
    constants.REMOVE_REMINDER_SUCCESS,
    constants.REMOVE_REMINDER_FAILURE,
);

export const {
    clearQuestionnaireRequest,
    clearQuestionnaireSuccess,
    clearQuestionnaireFailure,
} = createActions(
    constants.CLEAR_QUESTIONNAIRE_REQUEST,
    constants.CLEAR_QUESTIONNAIRE_SUCCESS,
    constants.CLEAR_QUESTIONNAIRE_FAILURE,
);
