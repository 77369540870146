import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Layout } from 'antd';
import { isObject } from 'underscore';

import ProgressHeader from '../ProgressHeader';
import Header from '../Header';
import './style.scss';
import icon from '../../assets/favicon.ico';

const { APP_NAME } = require('../../core/constants').default;

function MainLayout(props) {

    const { children, className, title, toolbar, backLink, progress, floatingHeader, headerTitle, hideProgressBar } = props;

    return (
        <div className={`page-wrap  ${className}`}>
            <Helmet
                title={!isObject(title) ? title : title.props.children}
                titleTemplate={`${APP_NAME}`}
                defaultTitle={`${APP_NAME}`}
                meta={[
                    { name: 'description', content: `${APP_NAME} website` },
                    { property: 'og:type', content: 'website' },
                ]}>
                <link rel="shortcut icon" href={icon} />
            </Helmet>
            { (progress || progress === 0)
                ? <ProgressHeader customClass={hideProgressBar} toolbar={toolbar} backLink={backLink} progress={progress} floating={floatingHeader} />
                : <Header headerTitle={headerTitle} />}
            <Layout.Content className="main-content">{children}</Layout.Content>
        </div>
    );
}

MainLayout.defaultProps = {
    toolbar: [],
};

MainLayout.propTypes = {
    backLink: PropTypes.any.isRequired,
    children: PropTypes.any.isRequired,
    className: PropTypes.any.isRequired,
    floatingHeader: PropTypes.any.isRequired,
    headerTitle: PropTypes.any.isRequired,
    progress: PropTypes.any.isRequired,
    title: PropTypes.shape({
        props: PropTypes.shape({
            children: PropTypes.any,
        }),
    }).isRequired,
    toolbar: PropTypes.any,
    hideProgressBar: PropTypes.any.isRequired,
};

// MainLayout.defaultProps = {
//     toolbar: [],
//     backLink: null,
//     progress: null,
//     floatingHeader: null,
//     className: null,
//     title: null,
// };

// MainLayout.propTypes = {
//     toolbar: PropTypes.array,
//     backLink: PropTypes.object,
//     progress: PropTypes.number,
//     floatingHeader: PropTypes.bool,
//     className: PropTypes.string,
//     title: PropTypes.node.isRequired,
// };

export default MainLayout;
