import React from 'react';
import { Row, Col, Button } from 'antd';
import { useSelector } from 'react-redux';

import moment from 'moment';
import { isNull } from 'underscore';
import MainLayout from '../../layouts/MainLayout';
import Icon from '../../elements/CustomSVGIcon';
import './style.scss';
import RiskInfographic from '../../components/RiskInfographic';
import history from '../../core/utils/history';

function RiskScorePage() {
    // Redux
    const riskScore = useSelector(state => state.questionnaire.riskScore);

    const backLink = {
        link: '/your-questionnaires',
        title: 'Export Risk Audit',
        subtitle: !isNull(riskScore?.data?.completion_time) ? moment(riskScore?.data?.completion_time).format('DD-MM-YYYY h:mm:ss') : '',
    };

    const handleBackBtn = () => history.push('/your-questionnaires');

    return (
        <MainLayout
            title={<div>Welcome</div>}
            className=""
            backLink={backLink}
            toolbar={[{ id: '001', label: 'Return to Dashboard', onClick: () => { handleBackBtn(); }, iconName: 'dashboard' }]}
            progress={riskScore?.data?.progress_percent}
            headerTitle
            floating
            floatingHeader=""
            hideProgressBar={riskScore?.data?.display_risk_score === 1 ? '' : 'd-none'}>
            <div className="container">
                <div className="form-quiz">
                    <div className="block">
                        { riskScore?.data?.display_risk_score === 1
                            ? (
                                <div className="block__header">
                                    <h3 className="h-3">Your Risk Score</h3>
                                </div>
                            )
                            : ''}
                        <div className="block__body">
                            <Row gutter={24} justify="center">
                                <Col xs={24} sm={100}>
                                    { riskScore?.data?.display_risk_score === 1 ? <RiskInfographic riskScore={riskScore?.score} riskText={riskScore?.scoreText} /> : '' }
                                    <h5 className="h-5"> { riskScore?.data?.display_risk_score === 1 ? 'About your score' : '' }</h5>
                                    <p className={`mt-1 fz-14 ${riskScore?.data?.display_risk_score}` === 1 ? 'text-center' : ''}>
                                        {riskScore?.msg}
                                    </p>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="blocks__footer">
                        <Button type="primary" onClick={handleBackBtn}>
                            <Icon name="dashboard" />
                            <span>Return to Dashboard</span>
                        </Button>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
}

export default RiskScorePage;
