import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_QUESTIONNAIRES_DATA_REQUEST: null,
    GET_QUESTIONNAIRES_DATA_SUCCESS: null,
    GET_QUESTIONNAIRES_DATA_FAILURE: null,

    // questionnaires medias
    QUESTIONNAIRES_MEDIA_REQUEST: null,
    QUESTIONNAIRES_MEDIA_SUCCESS: null,
    QUESTIONNAIRES_MEDIA_FAILURE: null,

    // questionnaire Answers
    QUESTIONNAIRES_RESPONSE_REQUEST: null,
    QUESTIONNAIRES_RESPONSE_SUCCESS: null,
    QUESTIONNAIRES_RESPONSE_FAILURE: null,
});

export const {
    getQuestionnairesDataRequest,
    getQuestionnairesDataSuccess,
    getQuestionnairesDataFailure,

    // questionnaire media
    questionnairesMediaRequest,
    questionnairesMediaSuccess,
    questionnairesMediaFailure,

    // questionnaire response
    questionnairesResponseRequest,
    questionnairesResponseSuccess,
    questionnairesResponseFailure,
} = createActions(
    constants.GET_QUESTIONNAIRES_DATA_REQUEST,
    constants.GET_QUESTIONNAIRES_DATA_SUCCESS,
    constants.GET_QUESTIONNAIRES_DATA_FAILURE,

    // questionnaire media request
    constants.QUESTIONNAIRES_MEDIA_REQUEST,
    constants.QUESTIONNAIRES_MEDIA_SUCCESS,
    constants.QUESTIONNAIRES_MEDIA_FAILURE,

    // questionnaire response
    constants.QUESTIONNAIRES_RESPONSE_REQUEST,
    constants.QUESTIONNAIRES_RESPONSE_SUCCESS,
    constants.QUESTIONNAIRES_RESPONSE_FAILURE,
);
