const data = {
    id: 1,
    company_id: 1,
    companyModel: {
        id: 1,
        region_id: 1,
        name: 'Apple',
        profile_image_url: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Apple_logo_black.svg/1920px-Apple_logo_black.svg.png',
        address: '2376  Timbercrest Road, Anchorage, Alaska',
        postcode: '99501',
        contact_name: 'Jeff',
        email_address: 'jeff@jeff.com',
        phone_number: null,
        status: 1, // 0 - Inactive, 1 - Active
        create_time: '2021-06-26T16: 01: 21+0000',
        update_time: '2021-06-26T16: 01: 21+0000',
        questionnaireInstanceModelList: [
            {
                id: 1,
                company_id: 1,
                questionnaire_id: 1,
                questionnaireModel: {
                    id: 1,
                    title: 'Vivamus scelerisque tortor a condimentum',
                    status: 1, // 0 - Inactive, 1 - Active
                    create_time: '2021-06-26T16: 01: 21+0000',
                    update_time: '2021-06-26T16: 01: 21+0000',
                    questionCategoryModelList: [
                        {
                            id: 1,
                            questionnaire_id: 1,
                            title: 'iaculis eu non diam phasellus',
                            subtitle: 'eu turpis egestas pretium aenean',
                            status: 1, // 0 - Inactive, 1 - Active
                            create_time: '2021-06-26T16: 01: 21+0000',
                            update_time: '2021-06-26T16: 01: 21+0000',
                            questionModelList: [
                                {
                                    id: 1,
                                    question_category_id: 1,
                                    region_id: null,
                                    parent_id: null,
                                    text: 'Phasellus varius leo molestie, pharetra',
                                    subtext: 'Nullam et sem a dolor',
                                    risk_impact: 4,
                                    risk_probability_yes: 0.1,
                                    risk_probability_no: 0.9,
                                    required_parent_number_value: null,
                                    type: 0, // 0 - Yes_no, 1 - File
                                    is_parent: 0, // 0 - No, 1 - Yes
                                    status: 1, // 0 - Inactive, 1 - Active
                                    create_time: '2021-06-26T16: 01: 21+0000',
                                    update_time: '2021-06-26T16: 01: 21+0000',
                                },
                                {
                                    id: 2,
                                    question_category_id: 1,
                                    region_id: null,
                                    parent_id: null,
                                    text: 'mi in nulla posuere sollicitudin',
                                    subtext: 'sed pulvinar proin gravida hendrerit',
                                    risk_impact: 2,
                                    risk_probability_yes: 0.1,
                                    risk_probability_no: 0.9,
                                    required_parent_number_value: null,
                                    type: 1, // 0 - Yes_no, 1 - File
                                    is_parent: 0, // 0 - No, 1 - Yes
                                    status: 1, // 0 - Inactive, 1 - Active
                                    create_time: '2021-06-26T16: 01: 21+0000',
                                    update_time: '2021-06-26T16: 01: 21+0000',
                                },
                            ],
                        },
                        {
                            id: 2,
                            questionnaire_id: 1,
                            title: 'Duis ac ullamcorper nibh, quis',
                            subtitle: 'Nullam iaculis accumsan scelerisque. Nunc',
                            status: 1, // 0 - Inactive, 1 - Active
                            create_time: '2021-06-26T16: 01: 21+0000',
                            update_time: '2021-06-26T16: 01: 21+0000',
                            questionModelList: [
                                {
                                    id: 3,
                                    question_category_id: 2,
                                    region_id: null,
                                    parent_id: null,
                                    text: 'quam id leo in vitae',
                                    subtext: 'euismod in pellentesque massa placerat',
                                    risk_impact: null,
                                    risk_probability_yes: null,
                                    risk_probability_no: null,
                                    required_parent_number_value: null,
                                    type: 1, // 0 - Yes_no, 1 - File
                                    is_parent: 1, // 0 - No, 1 - Yes
                                    status: 1, // 0 - Inactive, 1 - Active
                                    create_time: '2021-06-26T16: 01: 21+0000',
                                    update_time: '2021-06-26T16: 01: 21+0000',
                                },
                                {
                                    id: 4,
                                    question_category_id: 2,
                                    region_id: null,
                                    parent_id: 3,
                                    text: 'tortor vitae purus faucibus ornare',
                                    subtext: 'vitae sapien pellentesque habitant morbi',
                                    risk_impact: 3,
                                    risk_probability_yes: 0.1,
                                    risk_probability_no: 0.9,
                                    required_parent_number_value: 1,
                                    type: 1, // 0 - Yes_no, 1 - File
                                    is_parent: 0, // 0 - No, 1 - Yes
                                    status: 1, // 0 - Inactive, 1 - Active
                                    create_time: '2021-06-26T16: 01: 21+0000',
                                    update_time: '2021-06-26T16: 01: 21+0000',
                                },
                            ],
                            mediaModelList: [
                                {
                                    id: 1,
                                },
                                {
                                    id: 2,
                                },
                            ],
                        },
                    ],
                },
                url_id_parameter: 'tMe01HFRiD',
                start_time: '2021-06-26T16: 01: 21+0000',
                completion_time: '2021-06-26T16: 01: 21+0000',
                view_count: 0,
                risk_score: null,
                status: 1, // 0 - Inactive, 1 - Active, 2 - Completed
                create_time: '2021-06-26T16: 01: 21+0000',
                update_time: '2021-06-26T16: 01: 21+0000',
                answerModelList: [
                    {
                        id: 1,
                        question_id: 1,
                        question_category_id: 1,
                        questionnaire_instance_id: 1,
                        user_id: 1,
                        reminder_set_time: '2021-06-26T16: 01: 21+0000',
                        reminder_note: null,
                        number_value: null,
                        question_type: 0, // 0 - Yes_no, 1 - File
                        status: 1, // 0 - Inactive, 1 - Notification, 2 - Saved
                        create_time: '2021-06-26T16: 01: 21+0000',
                        update_time: '2021-06-26T16: 01: 21+0000',
                        mediaModel: null,
                    },
                    {
                        id: 2,
                        question_id: 2,
                        question_category_id: 1,
                        questionnaire_instance_id: 1,
                        user_id: 1,
                        reminder_set_time: null,
                        reminder_note: null,
                        number_value: null,
                        question_type: 1, // 0 - Yes_no, 1 - File
                        status: 2, // 0 - Inactive, 1 - Notification, 2 - Saved
                        create_time: '2021-06-26T16: 01: 21+0000',
                        update_time: '2021-06-26T16: 01: 21+0000',
                        mediaModel: {
                            id: 3,
                        },
                    },
                    {
                        id: 3,
                        question_id: 3,
                        question_category_id: 2,
                        questionnaire_instance_id: 1,
                        user_id: 1,
                        reminder_set_time: null,
                        reminder_note: null,
                        number_value: 1,
                        question_type: 0, // 0 - Yes_no, 1 - File
                        status: 2, // 0 - Inactive, 1 - Notification, 2 - Saved
                        create_time: '2021-06-26T16: 01: 21+0000',
                        update_time: '2021-06-26T16: 01: 21+0000',
                        mediaModel: null,
                    },
                ],
            },
        ],
    },
    email_address: 'emily@emily.com',
    first_name: 'Emily',
    surname: 'Mason',
    password_hash: 'passwordEmily1',
    access_token: 'tMe01HFRiD',
    refresh_token: 'tMe01HFRiD',
    refresh_token_expiry_time: '2021-06-26T16: 01: 21+0000',
    password_recovery_code: 'tMe01HFRiD',
    date_of_birth: '10/10/1990',
    type: 0, // 0 - User, 1 - Admin
    status: 1, // 0 - Inactive, 1 - Active
    create_time: '2021-06-26T16: 01: 21+0000',
    update_time: '2021-06-26T16: 01: 21+0000',
};

export default data;
