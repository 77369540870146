import { call, put, takeEvery, all } from 'redux-saga/effects';
import { fetchApiAuth } from '../utils/api';

import {
    getQuestionnairesDataSuccess,
    getQuestionnairesDataFailure,

    // questionnaires media
    questionnairesMediaSuccess,
    questionnairesMediaFailure,

    // questionnaire response
    questionnairesResponseSuccess,
    questionnairesResponseFailure,
} from './questionnairesActions';

const {
    GET_QUESTIONNAIRES_DATA_REQUEST,
    QUESTIONNAIRES_MEDIA_REQUEST,
    QUESTIONNAIRES_RESPONSE_REQUEST,
} = require('./questionnairesActions').constants;

function* getQuestionnairesData(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `company/${action.payload.companyId}/company-questionnaires`,
        });
        yield put(getQuestionnairesDataSuccess(response.data));
    } catch (e) {
        yield put(getQuestionnairesDataFailure(e.message ? e.message : e));
    }
}

function* questionnairesMedia(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `company/${action.payload.companyId}/company-questionnaire-media?companyQuestionnaireId=${action.payload.companyQuestionnaireId}`,
        });

        yield put(questionnairesMediaSuccess(response.data));
    } catch (e) {
        yield put(questionnairesMediaFailure(e.response ? e.response.data.message : e));
    }
}

function* questionnairesResponse(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `questionnaire/${action.payload.questionnaireId}/all-questions?companyQuestionnaireId=${action.payload.companyQuestionnaireId}&companyId=${action.payload.companyId}`,
        });

        yield put(questionnairesResponseSuccess(response.data));
    } catch (e) {
        yield put(questionnairesResponseFailure(e.response ? e.response.data.message : e));
    }
}

/**
 * Watch actions
 */
export default function* questionniresSaga() {
    yield all([
        takeEvery(GET_QUESTIONNAIRES_DATA_REQUEST, getQuestionnairesData),
        takeEvery(QUESTIONNAIRES_MEDIA_REQUEST, questionnairesMedia),
        takeEvery(QUESTIONNAIRES_RESPONSE_REQUEST, questionnairesResponse),
    ]);
}
