/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Radio, Dropdown, Upload, Menu, Form, Input, DatePicker, Select, InputNumber, message, Tooltip } from 'antd';
import { isUndefined, isNull, isArray, filter, pluck } from 'underscore';
import moment from 'moment';
import Icon from '../CustomSVGIcon';
import { getApiUrl, getAuthHeaders } from '../../core/utils/api';
import './style.scss';

const { Option } = Select;

function Question(props) {
    const { question, toggleReminderModal, setQuestionToRemind, updateAnswer, removeReminder, allUserAnswers, convertStringToArray } = props;

    const menu = (reminder) => (
        <Menu>
            {reminder ? (
                <>
                    <Menu.Item key="1" onClick={() => removeReminder(reminder)}>Remove reminder</Menu.Item>
                    <Menu.Item key="2" onClick={() => { toggleReminderModal(); setQuestionToRemind(question.id); }}>Update reminder</Menu.Item>
                </>
            ) : (
                <Menu.Item key="0" onClick={() => { toggleReminderModal(); setQuestionToRemind(question.id); }}>
                    Add reminder
                </Menu.Item>
            )}

        </Menu>
    );

    const getAnswer = (answer) => {
        const label = answer.title;
        let categoryId = answer.category_id;
        let questionId = answer.question_id;
        let answerType = answer.answer_type;
        let answerId = answer.id;

        if (answer.length) {

            categoryId = answer[0].category_id;
            questionId = answer[0].question_id;
            answerType = answer[0].answer_type;
            answerId = answer[0].id;
        }

        const name = `cat-${categoryId}-question-${questionId}-id-${answerId}`;

        let existingValue = null;

        if (answer.value) {
            existingValue = answer.value;
        } else if (answer.numeric_value) {
            existingValue = answer.numeric_value;
        } else if (answer.media_value) {
            existingValue = answer.media_value;
        } else {
            existingValue = null;
        }

        const checkForOptional = () => {
            if (answer.optional) {
                if (answer.has_optional_conditions) {
                    const conditions = answer.optional_condition_answers.split(',').map(Number);
                    const userAnswerIds = pluck(allUserAnswers, 'answer_id');
                    if (conditions.every(i => userAnswerIds.includes(i))) {
                        return false;
                    }
                    return true;
                }
                return false;
            }
            return true;
        };

        const onChange = (e) => updateAnswer(e, categoryId, questionId, answerType, answerId, name, answer.optional, answer.has_optional_conditions);

        const uploadProps = {
            action: `${getApiUrl()}/dropzone/update`,
            headers: getAuthHeaders(),
            name: 'files[]',
            maxCount: 1,
            beforeUpload: file => {
                if (file.size > 5000000) {
                    message.error('Max file size allowed 5mb');
                }
                return file.size > 5000000 ? Upload.LIST_IGNORE : true;
            },
            onChange({ file }) {
                if (file.status !== 'uploading') {
                    updateAnswer(file, categoryId, questionId, answerType, answerId, name);
                }
            },
            defaultFileList: [
                {
                    uid: answer.media_value,
                    name: answer.final_answer_value,
                    status: 'done',
                },
            ],
        };

        const rules = [{ required: checkForOptional(), message: 'This field is required' }];

        switch (answerType) {
        case 1:
            // text input
            return (
                <Form.Item rules={rules} key={name} name={name} label={label}>
                    <Input defaultValue={existingValue} onChange={onChange} />
                </Form.Item>
            );

        case 2:
            // number input
            return (
                <Form.Item rules={rules} key={name} name={name} label={label}>
                    <InputNumber defaultValue={existingValue} onChange={onChange} />
                </Form.Item>
            );

        case 3:
            // text area input
            return (
                <Form.Item rules={rules} key={name} name={name} label={label}>
                    <Input.TextArea defaultValue={existingValue} onChange={onChange}>
                        {existingValue}
                    </Input.TextArea>
                </Form.Item>
            );
        case 4:
            // select input
            return (
                <Form.Item rules={rules} key={name} name={name} label={label}>
                    <Select
                        onChange={onChange}
                        placeholder="Please select"
                        defaultValue={answer.find(a => a.numeric_value)?.id || null}
                        style={{ width: '100%' }}>
                        {answer.map(a => <Option key={a?.id} selected={a.numeric_value} value={a.id}>{a?.title}</Option>)}
                    </Select>
                </Form.Item>
            );

        case 5:
            // file upload
            return (
                <>
                    <div className="flex-center">
                        <Form.Item rules={rules} key={name} name={name} label={label} style={{ maxWidth: 300 }}>
                            <Upload accept=".pdf, .doc, .docx, .xlsx, .ppt, .pptx" {...uploadProps}>
                                <Button type="default" className="w-small">
                                    <span className="c_blue">Upload</span>
                                </Button>
                            </Upload>
                        </Form.Item>

                    </div>
                    <span
                        className="ml-2 text-secondary"
                        style={{ maxWidth: 125, marginLeft: 10, color: 'red' }}>
                        (5 mb max file size allowed. Only PDF, PPTX, DOCX and XLSX format is allowed)
                    </span>
                </>
            );
        case 6:
            // date input
            return (
                <Form.Item rules={rules} key={name} name={name} label={label}>
                    <DatePicker defaultValue={isUndefined(existingValue) || isNull(existingValue) ? '' : moment(existingValue)} format="DD-MM-YYYY" onChange={onChange} />
                </Form.Item>
            );
        case 7:
            // email input
            return (
                <Form.Item rules={rules} key={name} name={name} label={label}>
                    <Input defaultValue={existingValue} onChange={onChange} />
                </Form.Item>
            );
        case 8:
            // radio input
            return (
                <div key={name} className="ant-row ant-row-middle">
                    <Form.Item rules={rules} key={name} name={name}>
                        <Radio.Group
                            name={name}
                            defaultValue={answer?.find(a => a.numeric_value)?.id || null}
                            value={existingValue}
                            onChange={onChange}>
                            {answer && answer.map(a => (
                                <Radio key={a.id} value={a.id}>
                                    {a.title}
                                </Radio>
                            ))}
                        </Radio.Group>
                    </Form.Item>
                </div>
            );

        case 9: {
            // multi select
            const res = answer?.find(a => a.multi_value)?.multi_value || [];
            const newArr = [];

            // convert res to array
            if (isArray(res)) {
                res.forEach(e => {
                    newArr.push(+e);
                });
            }

            return (
                <Form.Item rules={rules} key={name} name={name} label={label}>
                    <Select
                        onChange={onChange}
                        mode="multiple"
                        placeholder="Please select"
                        defaultValue={newArr}
                        style={{ width: '100%' }}>
                        {answer.map(a => (
                            <Option key={a.id} value={a.id}>
                                <Tooltip placement="left" title={a.title}>{a.title}</Tooltip>
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            );
        }
        default:
            return null;
        }

    };

    // answer that have group ex- radio, select
    const groupSelectAnswers = () => {
        const groupedAnswers = [];
        if (!isUndefined(question.answers)) {
            question.answers.forEach(a => {
                if (a.group_by) {
                    if (groupedAnswers[a.group_by]) {
                        groupedAnswers[a.group_by].push(a);
                    } else {
                        groupedAnswers[a.group_by] = [a];
                    }
                } else {
                    groupedAnswers.push(a);
                }
            });
        }

        const sortedAnswers = Object.values(groupedAnswers).sort((a, b) => (a[0] ? a[0].id : a.id) - (b[0] ? b[0].id : b.id));
        return sortedAnswers.map(a => getAnswer(a));
    };

    const questionBody = () => (
        <Row gutter={[24, { xs: 24, sm: 32, lg: 40 }]} type="flex" className="block__row" key={question?.id}>
            <Col xs={3} type="flex">
                <Dropdown overlay={menu(question.reminder)} trigger={['click']}>
                    <Button type="text" className="menu-dropdown">
                        <Icon name="dots-v" />
                    </Button>
                </Dropdown>
            </Col>

            <Col xs={21}>
                <Row>
                    <Col>
                        <p className="question-text">{question?.text}</p>
                        {(question?.subtext)
                            ? (<p className="text-secondary" dangerouslySetInnerHTML={{ __html: question.subtext }} />) : null}
                        { (question?.reminder)
                            ? (
                                <p className="c_blue ant-row  ant-row-middle mt-1">
                                    <Icon name="notification" className="fz-24" />
                                    <span className="ml-1">{moment(question?.reminder.set_time).format('DD-MM-YYYY')}</span>
                                </p>
                            )
                            : null}
                    </Col>
                </Row>
                <Row>
                    <Col xs={24}>
                        {groupSelectAnswers()}
                    </Col>
                </Row>
            </Col>

        </Row>
    );

    const checkDependency = (ques) => {

        const dependencyQues = ques.dependent_on_question;
        const dependencyAnsValue = ques.dependent_question_value;
        let isDependencyAnswered = null;

        // since its a select type answer_id is equivalent to numeric_value
        const dependencyAnswered = allUserAnswers.find(a => +a.question_id === +dependencyQues);

        if (dependencyAnswered?.numeric_value && allUserAnswers.find(a => +a.numeric_value === +dependencyAnsValue)) {
            isDependencyAnswered = allUserAnswers.find(a => +a.numeric_value === +dependencyAnsValue);
        }

        if (dependencyAnswered?.multi_value && dependencyAnswered.multi_value) {

            const multiValues = !isArray(dependencyAnswered.multi_value) ? convertStringToArray(dependencyAnswered.multi_value) : dependencyAnswered.multi_value;

            const filterVal = filter(multiValues, (val) => +val === +dependencyAnsValue);
            if (filterVal.length > 0) {
                isDependencyAnswered = true;
            }
        }

        if (isDependencyAnswered) {
            return questionBody();
        }
        return '';

    };

    return (
        !question?.dependent_on_question ? questionBody() : (
            allUserAnswers && checkDependency(question)
        )

    );
}

Question.defaultProps = {
    allUserAnswers: [],
};
Question.propTypes = {
    question: PropTypes.object.isRequired,
    updateAnswer: PropTypes.func.isRequired,
    toggleReminderModal: PropTypes.any.isRequired,
    setQuestionToRemind: PropTypes.func.isRequired,
    removeReminder: PropTypes.func.isRequired,
    allUserAnswers: PropTypes.array,
    convertStringToArray: PropTypes.func.isRequired,
};

export default Question;
